import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from "react-redux";
import WalletWrapper from "../Wallet";

const getActiveTheme = (themeMode) => {
  return createTheme({
    pageColor:      themeMode === 'true' || !themeMode ? '#efefef': '#161616',
    // BackgroundColors
    bgPrimary:      themeMode === 'true' || !themeMode ? '#efefef': '#161616',
    bgSecondary:    themeMode === 'true' || !themeMode ? '#e1e0e0': '#050609',
    // TextColors
    textPrimary:    themeMode === 'true' || !themeMode ? '#000000': '#ffffff',
    textSecondary:  themeMode === 'true' || !themeMode ? 'white': 'black',
    //buttonColors
    btnPrimary:     themeMode === 'true' || !themeMode ? '#FF017A': '#FF017A',
    btnTxtPrimary:  themeMode === 'true' || !themeMode ? '#ffffff': '#ffffff',
    btnPrimaryHover:themeMode === 'true' || !themeMode ? '#d3287d': '#d3287d',
    btnDisable:       '#cccccc',
    btnTxtDisable:    '#666666',
    btnborderDisable: '#999999',
    // others
    switchColor:    themeMode === 'true' || !themeMode ? '#161616': '#efefef',

    // gradients
    gradientPrimary:themeMode === 'true' || !themeMode ? 'linear-gradient(to bottom, #5a10354f, #dfdfdf)' : 'linear-gradient(to bottom, #5a1035, #080917)',
    gradientCard:themeMode === 'true' || !themeMode ? 'linear-gradient(to top, #5a10354f, #dfdfdf)' : 'linear-gradient(to top, #5a1035, #080917)',

    //ImageFilter
    imgPrimary:     themeMode === 'true' || !themeMode? 'invert(100%)' : 'invert(0%)',
  })
}
const ThemeContainer = () => {
  const currentTheme = useSelector((state) => state.LDTheme.value);
  const [activeTheme, setActiveTheme] = useState(getActiveTheme(currentTheme));

  useEffect(()=>{    
    setActiveTheme(getActiveTheme(currentTheme))
  },[currentTheme]);
  
  return <ThemeProvider theme={activeTheme}><CssBaseline /><WalletWrapper /></ThemeProvider>;
}

export default ThemeContainer;