import { Container } from "@mui/material";
import React from "react";
import { useAccount } from "wagmi";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";

const LockerMenu = (props) => {
  const { address } = useAccount();
  let user = '';
  if (address) {
    user = {
      title: "My Locks",
      link: "/my-locks",
    }
  }
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    {
      title: "Home",
      link: "/",
    },
    {
      title: "Locker",
      link: "/locker",
    },
    user,
    {
      title: "View Chart",
      link: "https://www.dextools.io/app/en/ether/pair-explorer/0x192f75cbb3d6fe7af8d1562d681861b424841406",
      target: "_blank",
    },
    {
      title: "Swap",
      link: "https://unilock.finance/swap/#/swap?chainId=1&inputCurrency=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48&outputCurrency=0xae91853153ac04ce748976534b653133c4e8ac4b",
      customClass: "",
      target: "_blank",
    },
  ];
  return (
    <Container maxWidth="xl">
      <DesktopMenu menuList={menuList} />
      <MobileMenu menuList={menuList} />
    </Container>
  );
};

export default LockerMenu;
