import React, { useState } from "react";
import {
  LogoHeading,
  LockImage,
  PromptImg,
  PromptText,
  SecondBtn,
  FirstBtn,
  TwoButtons,
  WhaleImage,
  Background,
  ModalBox,
  ApproveBtn,
} from "./styles";
import { Container, Grid, Modal } from "@mui/material";
import logo2 from "../../../assets/Images/logo2.png";
import Homepage_Card from "../../Components/Cards/HomePageCard";
import Footer from "../../Components/Footer";
import { P, SectionWrapper } from "../../Styles/style";
import HeaderImg from "../../../assets/Images/logo2.png";
import LockerMenu from "../../Components/LockerMenu";
import { usePreviewHooks } from "../../../Hooks/usePreviewHooks";

const MainPage = () => {
  const { noOfProjects , getInvestment} = usePreviewHooks()
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Background>
        <LockerMenu link="/home"/>
        <SectionWrapper p="0px 0px">
          <Container maxWidth="xl">
            <WhaleImage>
              <LockImage src={HeaderImg} />
              <LogoHeading>UNI LOCK</LogoHeading>
            </WhaleImage>
            <P align="center" p="10px 0px">
              Keep your projects safe and trustworthy with UniLock.<br></br>
              With our state of the art locker, you can rest easy knowing that
              <br></br>
              your favorite project's liquidity is in safe hands!
            </P>
            <TwoButtons>
              <FirstBtn href="/locker">Liquidity Locker</FirstBtn>
              <SecondBtn href="/locked">View Locks</SecondBtn>
              <Modal
                open={open}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <ModalBox>
                  <PromptImg src={logo2}></PromptImg>
                  <PromptText id="child-modal-title">
                    Coming Soon
                  </PromptText>
                  <ApproveBtn margin="15px 0px" onClick={handleClose}>
                    Close
                  </ApproveBtn>
                </ModalBox>
              </Modal>
            </TwoButtons>
            <Grid container spacing="8" justifyContent="center" sx={{ mt: 5 }}>
              <Homepage_Card valuetext="TBA" tokentext="Total Tokens Created" />
              <Homepage_Card
                valuetext={getInvestment}
                tokentext="Liquidity Lock Value"
              />
              <Homepage_Card valuetext={noOfProjects} tokentext="Projects Locked" />
            </Grid>
          </Container>
        </SectionWrapper>
        <Footer />
      </Background>
    </div>
  );
};

export default MainPage;
