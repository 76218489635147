import styled from "@emotion/styled";
import { CardContent } from "@mui/material";
import LeftSingleArrow from '@mui/icons-material/ArrowBackIosNew';

const Background = styled.div`
  /* z-index: 1; */
  position: relative;
  /* background-color: #63c6ff; */
  min-height: 100vh;
`;

const CustomCard = styled(CardContent)`
  padding: 20px;
  max-width: 500px;
  background-color: ${props => props.theme.bgPrimary};
  border: none;
  border-radius: 15px;
  border: 1px solid #3e3944;
`;

const HeadingText = styled.p`
  font-family: Poppins-Medium;
  font-size: 15px;
  color: ${props => props.theme.textPrimary};
  margin: 00px 0px 0px 0px;
`;

const FirstCell = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const CustomLockBtn = styled.a`
  margin: 0;
  cursor: pointer;
  border: none;
  font-size: 12px;
  color: ${props => props.theme.textPrimary};
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #ff017a;
`;
const CustomArrowBtn = styled.a`
  margin: 0;
  border: none;
  font-size: 12px;
  text-decoration: none;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: #ff017a;
`;

const CustomLeftSingleArrow = styled(LeftSingleArrow)`
  fill: ${props => props.theme.textPrimary};
`

const TokenImg = styled.img`
  width: 100%;
  max-width: 65px;
  margin-left: 65px;
  height: auto;
  @media screen and (max-width: 350px) {
    margin-left: 35px;
  }
`;

const AddressDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const Text = styled.p`
  margin: 0;
  color: ${props => props.theme.textPrimary};
  font-size: 12px;
  padding: 0px 5px;
  font-family: Poppins-Light;
`;

const TextArea = styled.textarea`
  margin: 0;
  color: ${props => props.theme.textPrimary};
  font-size: 12px;
  padding: 0px 5px;
  background-color: transparent;
  border: none;
  width: 70%;
  resize: none;
  font-family: Poppins-Light;
  outline: none;
  @media screen and (max-width: 530px) {
    min-height: 40px;
  }
`;

const SubText = styled.p`
  margin: 0;
  color: ${props => props.theme.textPrimary};
  font-size: 12px;
  padding: 0px 5px;
  margin: 10px 0px;
  text-align: left;
`;

const CopyTextImg = styled.img`
  width: 100%;
  cursor: pointer;
  max-width: 20px;
  height: auto;
`;

const LinkImg = styled.img`
  width: 100%;
  cursor: pointer;
  margin: 0 10px 0 2px;
  max-width: 15px;
  height: auto;
`;

const TokenDivSwap = styled.div`
  background-color: #ff017a;
  padding: 10px 20px;
  margin: 20px 0px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DetailsDiv = styled.div`
  background-color: ${props => props.theme.bgSecondary};
  padding: 5px 20px;
  margin: 10px 0px 0px;
  border: none;
  border-radius: 5px;
`;

const InnerDiv = styled.div`
  justify-content: space-between;
  display: flex;
  margin: 10px 0px;
  align-items: center;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`;

const FirstText = styled.p`
  font-family: Poppins-Light;
  color: #828384;
  margin: 0px;
  font-size: 14px;
`;

const SecondText = styled.p`
color: ${props => props.theme.textPrimary};
  font-family: Poppins-Light;
  margin: 0px;
  font-size: 14px;
`;

const TimerDivFull = styled.div`
  background-color: #ff017a;
  padding: 6px;
  border-radius: 3px;
  margin: 10px 0px;
`;

const TimerDivEmpty = styled.div`
  background-color: #fff;
  padding: 6px;
  border-radius: 3px;
  margin: 10px 0px;
`;

const TimerText = styled.p`
  margin: 0;
  color: ${props => props.theme.textPrimary};
  font-size: 12px;
  text-align: left;
`;

const CopyButton = styled.button`
  background-color: transparent;
  border: none;
  max-height: 25px;
`;

const LinkButton = styled.a`
  background-color: transparent;
  border: none;
  margin-top: 5px;
`;

export {
  Text,
  TextArea,
  TimerDivFull,
  SecondText,
  FirstText,
  InnerDiv,
  DetailsDiv,
  TokenDivSwap,
  LinkImg,
  CopyTextImg,
  SubText,
  Background,
  CustomCard,
  HeadingText,
  FirstCell,
  CustomArrowBtn,
  CustomLockBtn,
  TokenImg,
  AddressDiv,
  LinkButton,
  CopyButton,
  TimerText,
  TimerDivEmpty,
  CustomLeftSingleArrow,
};
