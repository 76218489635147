import { useWeb3Modal, Web3Button } from "@web3modal/react";
import { useAccount } from 'wagmi'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import { ConnectBTN, DisConnectBTN, ContentCopyIconC, CustomMenu, CustomMenuItem } from "./style";



const CustomWeb3Button = () => {
  const { address, isConnecting, isDisconnected } = useAccount();
  const { open } = useWeb3Modal()

  const [anchorEl, setAnchorEl] = useState(null);
  const openDropDown = Boolean(anchorEl);
  const handleDropDownOpen = (event) => setAnchorEl(event.currentTarget);
  const handleDropDownClose = () => setAnchorEl(null);

  useEffect(()=>{

  },[address])
  return (
    <ConnectBTN>
      {address && !isConnecting ?
        <>
          <button className="add">{address.slice(0, 4) + '...' + address.substr(address.length - 3)}</button>
          <button className="dd"onClick={handleDropDownOpen} ><ChevronRightIcon /></button>
          <CustomMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDropDown}
            onClose={handleDropDownClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem ><DisConnectBTN><Web3Button onClick={handleDropDownClose} /></DisConnectBTN></MenuItem>
            <CustomMenuItem onClick={handleDropDownClose}><ContentCopyIconC/> Copy Addesss</CustomMenuItem>
          </CustomMenu>
        </>
        :
        <button onClick={() => open()}>Connect Wallet</button>
      }
    </ConnectBTN>
  )
}

export default CustomWeb3Button