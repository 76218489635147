import styled from "@emotion/styled";
import { CardContent } from "@mui/material";

const Background = styled.div`
  /* z-index: 1; */
  position: relative;
  /* background-color: #63c6ff; */
  min-height: 100vh;
`;
const CustomCard = styled(CardContent)`
  padding: 30px 20px;
  margin-top: 50px;
  background-color: ${(props) => props.theme.bgPrimary};
  max-width: 500px;
  border: 1px solid #3e3944;
  border-radius: 15px;
`;

const LockImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 25px;
`;
const TokenImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 40px;
`;

const SearchImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 20px;
  position: absolute;
  top: 7px;
  right: 15px;
`;

const PairImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 32px;
  margin-left: 5px;
`;

const ImgText = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Medium;
  font-size: 20px;
  margin: 5px 0px;
`;
const LowerText = styled.p`
color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Light;
  font-size: 13px;
  margin: 20px 0px 15px;
`;
const Text = styled.p`
  font-family: Poppins-Medium;
  color: ${(props) => props.theme.textPrimary};
  font-size: 16px;
  margin: 15px 0px;
  @media screen and (max-width: 599px) {
    margin: 10px 0px;
  }
`;
const TokenDivEth = styled.div`
  background-color: ${(props) => props.theme.bgSecondary};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const TokenDivSwap = styled.div`
  background-color: #ff017a;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
const InputDiv = styled.div`
  position: relative;
  border: 1px solid #ff017a;
  border-radius: 5px;
  background-color: transparent !important;
`;

const CustomInput = styled.input`
  border: 0;
  border-radius: 5px;
  padding: 7px 45px 7px 15px;
  text-align: left;
  width: 100%;
  font-size: 14px;
  color: ${(props) => props.theme.textPrimary};
  outline: none;
  max-width: -webkit-fill-available;
  background-color: transparent !important;
  ::placeholder {
    font-size: 14px;
    color: ${(props) => props.theme.textPrimary};
  }
  &:-internal-autofill-selected {
    appearance: unset !important;
    background-image: unset !important;
    background-color: unset !important;;
    color: unset !important;
  }

`;
const SearchAddressDiv = styled.div``;

const DetailsDiv = styled.div`
  background-color: #050609;
  padding: 5px 15px;
  margin: 20px 0px 30px;
  border: none;
  border-radius: 5px;
  position: relative;
`

const InnerDiv = styled.div`
  justify-content: space-between;
  display: flex;
  margin: 10px 0px;
  align-items: center;
`;

const PairDiv = styled.div`
  display: flex;
  align-items: center;
`;

const FirstText = styled.p`
  font-family: Poppins-Light;
  color: #828384;
  margin: 0px;
  font-size: 14px;
`;

const SecondText = styled.p`
  font-family: Poppins-Light;
  margin: 0px;
  font-size: 14px;
`;

const ContinueBtn = styled.a`
  padding: 8px 10px;
  background-color: #ff017a;
  font-family: Poppins-SemiBold;
  border-radius: 5px;
  display: block;
  text-decoration: none;
`;

const ContinueBtnDisable = styled.button`
  padding: 8px 10px;
  background-color: ${props=>props.theme.btnDisable};
  color: ${props=>props.theme.btnTxtDisable};
  font-family: Poppins-SemiBold;
  border-radius: 5px;
  display: block;
  width: 100%;
  text-decoration: none;
`;

export {
  Text,
  LowerText,
  ImgText,
  PairImg,
  SearchImg,
  TokenImg,
  LockImg,
  CustomCard,
  Background,
  TokenDivEth,
  TokenDivSwap,
  InputDiv,
  CustomInput,
  SearchAddressDiv,
  DetailsDiv,
  InnerDiv,
  PairDiv,
  FirstText,
  SecondText,
  ContinueBtn,
  ContinueBtnDisable
};
