import { CustomCard, ValueText, TokenText } from "./styles";
import { Grid } from "@mui/material";
import { Loader } from "../../loader";

const Homepage_Card = (props) => {
    return (
      <Grid item xs={12} sm={6} md={4}>
        <CustomCard>
          {props.valuetext != 0 || props.valuetext != ''? 
            <ValueText>{props.valuetext}</ValueText>
            : 
            <Loader />
          }
          <TokenText>{props.tokentext}</TokenText>
        </CustomCard>
      </Grid>
    );
  };
  
  export default Homepage_Card;
