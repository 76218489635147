import { EthereumClient, modalConnectors, walletConnectProvider, } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";

import MainPage from '../Views/Pages/Main';
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import LockerHome from "../Views/Pages/LockerHome";
import LockLP from "../Views/Pages/LockLP";
import MyLockedPair from "../Views/Pages/MyLockedPair";
import PairDetails from "../Views/Pages/PairDetails";
import LockedPairs from "../Views/Pages/LockedPairs";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ALCHEMYKEY, WALLETCONNECTAPI } from "../Contracts";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from '@wagmi/core/providers/public'
import Model from './model.js'


const gradientAnimation = keyframes`
    0%{background-position: 0% 50%}
    50%{background-position: 0% 100%}
    100%{background-position: 0% 50%}
`

const BKComponent = styled.div`
    background-color: ${props=>props.theme.pageColor};
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    
    background: ${props=>props.theme.gradientPrimary};
    background-size: 100% 200%;
    -webkit-animation: ${gradientAnimation} 3s ease infinite;
    -moz-animation: ${gradientAnimation} 3s ease infinite;
    -o-animation: ${gradientAnimation} 3s ease infinite;
    animation: ${gradientAnimation} 3s ease infinite;
`
const WalletWrapper = () => {
    const chains = [chain.mainnet];
    
    
    // Wagmi client
    const { provider } = configureChains(chains, 
                        [alchemyProvider({ apiKey: ALCHEMYKEY, priority: 1 })],
                        [walletConnectProvider({ projectId: WALLETCONNECTAPI })],
                        [publicProvider()],
                    )
    
    const wagmiClient = createClient({
        autoConnect: true,
        connectors: modalConnectors({ appName: "web3Modal", chains }),
        provider,
    });

    // Web3Modal Ethereum Client
    const ethereumClient = new EthereumClient(wagmiClient, chains);

  return (
    <>
        <WagmiConfig client={wagmiClient}>
            <BKComponent />
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={<MainPage />} />
                    <Route path="/locker" element={<LockerHome />} />
                    <Route path="/lock-lp" element={<LockLP />} />
                    <Route path="/my-locks" element={<MyLockedPair />} />
                    <Route path="/detail" element={<PairDetails />} />
                    <Route path="/locked" element={<LockedPairs />} />
                </Routes>
            </BrowserRouter>
        </WagmiConfig>

        <Web3Modal
            projectId={WALLETCONNECTAPI}
            theme="dark"
            accentColor="magenta"
            ethereumClient={ethereumClient}
        />
    </>
  )
}

export default WalletWrapper