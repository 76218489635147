import {
  Text,
  TextArea,
  TimerDivFull,
  SecondText,
  FirstText,
  InnerDiv,
  DetailsDiv,
  TokenDivSwap,
  LinkImg,
  CopyTextImg,
  SubText,
  Background,
  CustomCard,
  HeadingText,
  FirstCell,
  CustomArrowBtn,
  CustomLockBtn,
  TokenImg,
  AddressDiv,
  LinkButton,
  CopyButton,
  TimerText,
  CustomLeftSingleArrow,
  TimerDivEmpty,
} from "./styles";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import Menu from "../../Components/LockerMenu";
import { SectionWrapper } from "../../Styles/style";
import tokenimg from "../../../assets/Images/tokenimg.png";
import copyimg from "../../../assets/Images/copyimg.png";
import linkimg from "../../../assets/Images/linkimg.png";
import { usePreviewHooks } from "../../../Hooks/usePreviewHooks";
import { SCANNER, SWAPLINK } from "../../../Contracts";
import { useDateFormate } from "../../../Hooks/useDateFormate";

const PairDetails = (props) => {
  const { getRmainingDays, setRemainingDays, getDateFormate, setDateFormate } =  useDateFormate()
  const url_string = window.location.href;
  const url = new URL(url_string);
  const pramValue = url.searchParams.get("pair");
  const { noOfLocksInContract } =  usePreviewHooks(pramValue)

  let data = noOfLocksInContract[0];

  useEffect(()=>{

    if (data) {
      const interval = setInterval(() => {
        setRemainingDays(data.unlockDateInt)
        setDateFormate(data.unlockDateInt)
      }, 1000);
      return () => {
        clearInterval(interval);
      };
      
    }
  })
  const handleCopy = () => {
    var text = document.getElementById("CopyAddress");
    text.select();
    navigator.clipboard.writeText(text.value);
  };

  return (
    <div>
      <Background>
        <Menu />
        <SectionWrapper p="0">
          <Container maxWidth="xl">
            <CustomCard>
              <FirstCell>
                <CustomArrowBtn href="/locked">
                  <CustomLeftSingleArrow/>
                </CustomArrowBtn>
                <TokenImg src={tokenimg} />
                <CustomLockBtn href={"lock-lp?pair="+pramValue}>
                  Lock Liquidity
                </CustomLockBtn>
              </FirstCell>
              <HeadingText>
                {data? <>{data.token1Name+" / "+data.token2Name}</>:null}
              </HeadingText>
              <AddressDiv>
                <TextArea
                  value={pramValue}
                  id="CopyAddress"
                  rows={1}
                />
                <CopyButton onClick={handleCopy}>
                  <CopyTextImg src={copyimg} />
                </CopyButton>
              </AddressDiv>
              <AddressDiv>
                <Text>Etherscan</Text>
                <LinkButton href={SCANNER+"address/"+pramValue} target="_blank">
                  <LinkImg src={linkimg} />
                </LinkButton>
                <Text>Uniswap</Text>
                {data?
                  <LinkButton href={SWAPLINK+"inputCurrency="+data.token1Add+"&outputCurrency="+data.token2Add} target="_blank">
                    <LinkImg src={linkimg} />
                  </LinkButton>
                :
                null}
              </AddressDiv>
              <Text style={{ marginTop: "10px" }}>Liquidity Locked</Text>
              <TokenDivSwap>
                <HeadingText>Uniswap V2</HeadingText>
                <HeadingText>{data? <>{data.lockedInPercent+"% Liquidity"}</> : null}</HeadingText>
              </TokenDivSwap>
              <DetailsDiv>
                <InnerDiv>
                  <FirstText>Total LP Tokens</FirstText>
                  <SecondText>{data? <>{data.lpSupply}</> : null}</SecondText>
                </InnerDiv>
                <InnerDiv>
                  <FirstText>Total Locked LP</FirstText>
                  <SecondText>{data? <>{data.lockedLP}</> : null}</SecondText>
                </InnerDiv>
              </DetailsDiv>
              <SubText>
                Please be aware that only the liquidity tokens are locked and
                not the actual dollar value. This changes as people trade. More
                liquidity tokens are also minted as people add liquidity to the
                pool.
              </SubText>
              <DetailsDiv>
                <InnerDiv>
                  <FirstText>Value</FirstText>
                  <SecondText>{data? <>{data.lockedLP+" LP"}</> : null}</SecondText>
                </InnerDiv>
                <InnerDiv>
                  <FirstText>Unlock Countdown</FirstText>
                </InnerDiv>
                <TimerDivFull />
                {/* <TimerDivEmpty /> */}
                <InnerDiv>
                  <TimerText>{data? <>{getRmainingDays}</> : null}</TimerText>
                  <TimerText>{data? <>{getDateFormate}</> : null}</TimerText>
                </InnerDiv>
              </DetailsDiv>
            </CustomCard>
          </Container>
        </SectionWrapper>
        <Footer />
      </Background>
    </div>
  );
};

export default PairDetails;
