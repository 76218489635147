import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toggleTheme } from "../../../../Redux/switchTheme";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../../assets/Images/logo2.png";
import {
  MaterialUISwitch,
  MenuLink,
  MobileMainMenu,
  SiteLogo,
  MoreVertIconC,
  CloseIconC,
  DrawerBox,
  DrawerDivider,
  MenuButton,
  LogoDiv,
  LogoText,
  DrawerText,
} from "../styles";
import CustomWeb3Button from "../../Web3Button";

const MobileMenu = (props) => {
  const currentTheme = useSelector((state) => state.LDTheme.value);
  const dispatch = useDispatch();
  const [state, setState] = useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("thest: ", anchor + " : " + open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  return (
    <MobileMainMenu>
      <MenuLink href="/" p="15px 0 15px">
        <LogoDiv>
          <SiteLogo src={logo} width="43"/>
          <LogoText>UNI LOCK</LogoText>
        </LogoDiv>
      </MenuLink>

      <div style={{ display: "flex" }}>
        <CustomWeb3Button />
        <MenuButton onClick={toggleDrawer("left", true)}>
          {state["left"] ? <CloseIconC /> : <MoreVertIconC />}
        </MenuButton>
      </div>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <DrawerBox
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <DrawerDivider>
            <MenuLink href="/">
              <LogoDiv>
                <SiteLogo src={logo} width="50" height="45" />
                <DrawerText>UNI LOCK</DrawerText>
              </LogoDiv>
            </MenuLink>
            <Divider />
            <List>
              {props.menuList.map((value, i) => (
                <MenuLink
                  key={i}
                  href={value.link}
                  target={value.target}
                  className={value.customClass + " d-block"}
                >
                  {value.title}
                </MenuLink>
              ))}
            </List>
          </DrawerDivider>
          <DrawerDivider>
            <List>
              {/* <FormControlLabel
                control={
                  <MaterialUISwitch sx={{ m: 1 }} checked={currentTheme} />
                }
                onClick={() => dispatch(toggleTheme())}
              /> */}
            </List>
          </DrawerDivider>
        </DrawerBox>
      </Drawer>
    </MobileMainMenu>
  );
};

export default MobileMenu;
