import { Container } from "@mui/system";
import { useState, useEffect } from "react";
import * as React from "react";
import {
  LowerText,
  ImgText,
  LockImg,
  CustomCard,
  Background,
  LinkText,
  PromptText,
  PromptImg,
  ConfigureDiv,
  Custombutton,
  CustomInputTime,
  CustomInputDate,
  CustomInput,
  EditableText,
  ApproveBtn,
  SecondText,
  FirstText,
  InnerDiv,
  LockDiv,
  DetailsDiv,
  PinkText,
  ModalBox,
  ErorrMsg
} from "./styles";
import Modal from "@mui/material/Modal";
import { SectionWrapper } from "../../Styles/style";
import Footer from "../../Components/Footer";
import Menu from "../../Components/LockerMenu/index";
import lockimg from "../../../assets/Images/lock.png";
import logo2 from "../../../assets/Images/logo2.png";
import { useAccount, useBalance, useContractWrite, usePrepareContractWrite } from "wagmi";
import CustomButton from "../../Components/Buttons";
import { usePair } from "../../../Hooks/useLpHooks";
import { ethers } from "ethers";
import { lockerAbi, lockerAddress, LPABI, SCANNER, testTempContract } from "../../../Contracts";
import { useLocker } from "../../../Hooks/useLockerHooks";

const LockLP = () => {
  const [balance, setBalance] = useState(0);
  const [ integerBalance, setIntegerBalance ] = useState('0');
  const [ eTHBalance, setETHBalance ] = useState(0);
  const [ formatedBalance, setFormatedBalance ] = useState('');
  const [epochTime, setEpochTime] = useState(false);

  const [amountError, setAmountError] = useState(false);
  const [amount, setAmount] = useState('');
  const [amountA, setAmountA] = useState('0');
  const [inputDateError, setInputDateError] = useState(false);
  const [inputDate, setInputDate] = useState('');
  const [inputTimeError, setInputTimeError] = useState(false);  
  const [inputTime, setInputTime] = useState('');
  const [unlockDate, setUnlockDate] = useState('mm/dd/yyy --:--');
  const [isLoading, setIsLoading] = useState(false);  
  const [trx, setTrx] = useState('');  
  const [open, setOpen] = useState(false);

  let url_string = window.location.href;
  let url = new URL(url_string);
  let pramValue = url.searchParams.get("pair");

  const { address, isConnected, isDisconnected } = useAccount();
  const { pair, pairTokenA, tokenAName, pairTokenB, tokenBName, hookError } = usePair(pramValue)

  const { lockFees, isAllowance } = useLocker(pramValue)
  const userTokenBalance = useBalance({
    address: address,
    token: pramValue,
    onSuccess(data) {
      setBalance(data.formatted)
      setIntegerBalance(ethers.utils.formatEther(data.value['_hex']))
      setFormatedBalance(Number(integerBalance).toFixed(4))
    },
    onError(error) {
      console.log('Balance Error', error)
    },
    watch: true,
    staleTime: 2_000,
  })
  const userETHBalance = useBalance({
    address: address,
    onSuccess(data) {
      setETHBalance(ethers.utils.formatEther(data.value['_hex']))
    },
    onError(error) {
      console.log('Balance Error', error)
    },
    watch: true,
    staleTime: 2_000,
  })

  const handleApprove = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: pramValue,
    abi: LPABI,
    functionName: 'approve',
    args: [lockerAddress,'115792089237316195423570985008687907853269984665640564039457584007913129639935'],
    onSuccess(data) {
      setTrx(data.hash)
      setIsLoading(false)
      handleOpen()
    },
    onError(error) {
      setIsLoading(false)
      console.log('handleApprove', error)
    },
  })
  const onClickApprove = () => {
    setIsLoading(true)
    handleApprove?.write()
  }
  const lockLPToken = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: lockerAddress,
    abi: lockerAbi,
    functionName: 'lockLPToken',
    args: [
      pramValue, //LP to be locked
      ethers.utils.parseEther(amountA), // value to be locked
      epochTime, // epoch Time
      "0x0000000000000000000000000000000000000000", // referral
      true, // fee in ETH?
      address,
    ],
    onSuccess(data) {
      setTrx(data.hash)
      setIsLoading(false)
      handleOpen()
    },
    onError(error) {
      setIsLoading(false)
      console.log('Locking ', error)
    },
  })
  const onClickLock = () => {
    setIsLoading(true)
    lockLPToken?.write()
  }
  useEffect(()=>{

  }, [isLoading, integerBalance]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAmount = (val, type='srch') => {
    if (type == "srch") {
      let valuea = val.target.value;
      setAmount(valuea);
      if (valuea == null || valuea == "") {
        setAmountA('0')
      }else{
        setAmountA(valuea)
      }

      if (valuea > Number(integerBalance)) {
        setAmountError(true);
      } else if (valuea <= 0) {
        setAmountError(true);
      } else {
        setAmountError(false);
      }
    } else if (type == "btn") {
      setAmount(val);
      if (val == null || val == "") {
        setAmountA('0')
      }else{
        setAmountA(val)
      }
      if (val > integerBalance) {
        setAmountError(true);
      } else if (val <= 0) {
        setAmountError(true);
      } else {
        setAmountError(false);
      }
    }
  };
  const handleDate = (e) => {
    let date = e.target.value
    let formatedDate = new Date(date).getTime()
    let currentTime = new Date()
    
    let datATime = date+' '+inputTime;
    let combinetime  = new Date(datATime).getTime()
    setEpochTime(combinetime/1000)
    setInputDate(date)
    if (formatedDate < currentTime) {
      setInputDateError(true)
      setUnlockDate('mm/dd/yyy --:--')
      return false;
    }
    setUnlockDate(datATime)
    setInputDateError(false)
    setInputTimeError(false)
  }
  const handleTime = (e) => {
    let time = e.target.value
    let currentTime = new Date().getTime()
    let datATime = inputDate+' '+time;

    let combinetime  = new Date(datATime).getTime()
    setEpochTime(combinetime/1000)
    setInputTime(time)
    if (combinetime <= currentTime) {
      setInputTimeError(true)
      setUnlockDate('')
      return false;
    }
    setUnlockDate(datATime)
    setInputTimeError(false)
  }

  return (
    <div>
      <Background>
        <Menu />
        <SectionWrapper p="1rem 0 0 0" alignItems="flex-start">
          <Container maxWidth="xl">
            <CustomCard>
              <LockImg src={lockimg} />
              <ImgText>Configure Lock</ImgText>
              <DetailsDiv>
                <InnerDiv>
                  <FirstText>Symbol</FirstText>
                  {pairTokenA ?
                    <SecondText>
                      <span title={pairTokenA}>{tokenAName}</span> / <span title={pairTokenB}>{tokenBName}</span>
                    </SecondText>
                  :
                      null
                  }
                </InnerDiv>
                <InnerDiv>
                  <FirstText>Balance</FirstText>
                  <SecondText>{ balance > 0 ? Number(balance).toFixed(2) : 0 }</SecondText>
                </InnerDiv>
                <InnerDiv>
                  <FirstText>Lock Amount</FirstText>
                  <ConfigureDiv>
                    {amountError? <ErorrMsg>Invalid amount</ErorrMsg> : null}
                    <EditableText 
                      placeholder="LP token" 
                      value={amount}
                      contentEditable={true}
                      type="number"
                      onChange={(event, type) =>
                        handleAmount(event, "srch")
                      }
                    />
                    <Custombutton onClick={()=>handleAmount(integerBalance, "btn")}>Max</Custombutton>
                  </ConfigureDiv>
                </InnerDiv>
              </DetailsDiv>
              <LowerText>Lock Duration</LowerText>
              <DetailsDiv>
                <InnerDiv>
                  <FirstText>Unlock Date</FirstText>
                  <ConfigureDiv>
                    {inputDateError? <ErorrMsg>Invalid date</ErorrMsg>: null}
                    <CustomInputDate 
                      value={inputDate}
                      contentEditable={true}
                      type="date"
                      onChange={(event) =>
                        handleDate(event)
                      }
                    />
                  </ConfigureDiv>
                </InnerDiv>
                <InnerDiv>
                  <FirstText>Unlock Time</FirstText>
                  <ConfigureDiv>
                    {inputTimeError ? <ErorrMsg>Invalid time</ErorrMsg> : null}
                    <CustomInputTime
                      type="time"
                      value={inputTime}
                      onChange={(event) =>
                        handleTime(event)
                      }
                    />
                    {/* <Custombutton>PM</Custombutton> */}
                  </ConfigureDiv>
                </InnerDiv>
              </DetailsDiv>
              {/* <LowerText style={{textAlign: 'left'}}>
                <label class="container">Withdrawer is not same?
                  <input type="checkbox" checked="checked" />
                  <span class="checkmark"></span>
                </label>
              </LowerText>
              <DetailsDiv>
                <InnerDiv>
                  <FirstText>Withdrawer Address</FirstText>
                </InnerDiv>
                <InnerDiv>
                  <ConfigureDiv className="divWidth">
                    {inputTimeError ? <ErorrMsg>Invalid time</ErorrMsg> : null}
                    <CustomInput
                      type="text"
                      value={inputTime}
                      onChange={(event) =>
                        handleTime(event)
                      }
                    />
                  </ConfigureDiv>
                </InnerDiv>
              </DetailsDiv> */}
              <LockDiv>
                {eTHBalance < lockFees ?
                  <InnerDiv><ErorrMsg>You have insufficient ETH to lock LP. Required {lockFees} ETH</ErorrMsg></InnerDiv>
                  :
                  null
                }
                <InnerDiv>
                  <FirstText>Service Fee</FirstText>
                  {lockFees? <PinkText>{lockFees} ETH</PinkText> : null}
                </InnerDiv>
                <InnerDiv>
                  <FirstText>Total Lockup Amount</FirstText>
                  <SecondText>{Number(amount).toFixed(2)} {pair}</SecondText>
                </InnerDiv>
                <InnerDiv>
                  <FirstText>Unlock Date</FirstText>
                  <SecondText>{unlockDate}</SecondText>
                </InnerDiv>
              </LockDiv>
              <CustomButton 
                isAllowance={isAllowance > 1? true: false}
                allowanceCall={onClickApprove}
                isLock={amount <= '0' || inputTime == "" || inputDate == "" || eTHBalance < lockFees || amountError || inputDateError || inputTimeError ? false : true}
                lockCall={onClickLock}
                loading={isLoading}
              />
              {/* eTHBalance < Number(lockFees) || !amountError || !inputDateError || !inputTimeError */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <ModalBox sx={{ width: 500 }}>
                  <PromptImg src={logo2}></PromptImg>
                  <PromptText id="child-modal-title">
                    TRANSACTION CREATED
                  </PromptText>
                  <LinkText href={SCANNER+"tx/"+trx} target="_blank" id="child-modal-description">
                    View On Etherscan
                  </LinkText>
                  <ApproveBtn margin="15px 0px" onClick={()=>handleClose()}>
                    Close
                  </ApproveBtn>
                </ModalBox>
              </Modal>
            </CustomCard>
          </Container>
        </SectionWrapper>
        <Footer />
      </Background>
    </div>
  );
};
export default LockLP;
