import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";

const CardImg = styled.img`
  width: 100%;
  max-width: 80px;
  height: auto;
  padding: 10px 20px 10px 0px;
  @media screen and (max-width: 599px) {
    padding: 5px 0;
  }
`;
const FirstDiv = styled.div`
  text-align: left;
  @media screen and (max-width: 599px) {
    text-align: -webkit-center;
  }
`;

const CustomGrid = styled(Grid)`
  background-color: ${(props) => props.theme.bgSecondary};
  border-radius: 5px;
  padding: 10px 0px;
  margin: 10px 0px;
`;
const InnerGrid = styled(Grid)`
  padding: 15px;
  &.countdown{text-align: left;}
  @media screen and (max-width: 899px) {
    justify-content: center;
    &.countdown{text-align: center;}
  }
  @media screen and (max-width: 599px) {
    text-align: -webkit-center;
    flex-direction: column;
  }
`;

const LockText1 = styled.p`
  margin: 0;
  color: ${props => props.theme.textPrimary};
  font-size: 16px;
`;

const LockText2 = styled.p`
  margin: 10px 0px;
  color: ${props => props.theme.textPrimary};
  font-size: 12px;
`;

const CustomLockBtn = styled.a`
  margin: 0;
  border: none;
  max-width: fit-content;
  text-decoration: none;
  padding: 2px 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: #ff017a;
`;

const CustomUnlockBtn = styled.a`
  margin: 0;
  border: none;
  text-decoration: none;
  max-width: fit-content;
  padding: 2px 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background-color: #fff;
`;

const LockImg = styled.img`
  width: 100%;
  max-width: 10px;
  filter: ${props => props.theme.imgPrimary};
  height: auto;
  margin: 0px 7px 0px 0px;
`;
const UnlockImg = styled.img`
  width: 100%;
  max-width: 10px;
  height: auto;
  margin: 0px 7px 0px 0px;
`;

const LockBtnText = styled.p`
  margin: 0;
  color: ${props => props.theme.textPrimary};
  font-size: 12px;
`;

const UnlockBtnText = styled.p`
  margin: 0;
  font-size: 12px;
  color: #ff017a;
`;

const TimerText = styled.p`
  margin: 0;
  color: ${props => props.theme.textPrimary};
  font-size: 12px;
`;

const TimerDivFull = styled.div`
  background-color: #ff017a;
  padding: 6px;
  border-radius: 3px;
  margin: 10px 0px;
  max-width: 300px;
  @media screen and (max-width: 899px) {
    margin: 10px auto;
  }
`;
const TimerDivEmpty = styled.div`
  background-color: #fff;
  padding: 6px;
  border-radius: 3px;
  margin: 10px 0px;
  max-width: 300px;
  @media screen and (max-width: 899px) {
    margin: 10px auto;
  }
`;

const WithDrawBtn = styled.a`
  margin: 0;
  color: ${props => props.theme.textPrimary};
  font-family: Poppins-SemiBold;
  font-size: 15px;
  border: none;
  padding: 5px 20px;
  border-radius: 5px;
  align-items: center;
  background-color: #ff017a;
  text-decoration: none;
  cursor: pointer;
  &.disabled {
    background-color: #76777a;
    cursor: default;
  }
`;

const ModalBox = styled(Box)`
  position: absolute;
  width: 80%;
  max-width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.bgPrimary};
  border: 1px solid #3e3944;
  box-shadow: 24;
  border-radius: 15px;
  outline: none;
  text-align: center;
  padding: 10px 20px;
`;
const PromptImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;
  margin: 15px 0px;
`;
const PromptText = styled.p`
  font-size: 18px;
  font-family: Poppins-Medium;
  color: ${(props) => props.theme.textPrimary};
  margin: 15px 0px;
`;
const SubText = styled(PromptText)`
  font-size: 14px;
  font-family: Poppins-Regular;
`;
const ColourText = styled(PromptText)`
  font-size: 22px;
  color: #FF017A;
`;

const ApproveBtn = styled.button`
  padding: 8px 10px;
  color: ${(props) => props.theme.textPrimary};
  background-color: #ff017a;
  font-family: Poppins-SemiBold;
  border-radius: 5px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  width: -webkit-fill-available;
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  &:disabled{
    background-color: ${props=>props.theme.btnDisable};
    color: ${props=>props.theme.btnTxtDisable};
    cursor: default;
  }
`;

const DetailsDiv = styled.div`
  background-color: ${(props) => props.theme.bgSecondary};
  padding: 5px 10px;
  margin: 0px 0px 20px 0px;
  border: none;
  border-radius: 5px;
`;
const InnerDiv = styled.div`
  justify-content: space-between;
  display: flex;
  margin: 10px 0px;
  align-items: center;
`;

const FirstText = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Light;
  color: #828384;
  margin: 0px;
  font-size: 14px;
`;

const SecondText = styled.p`
  font-family: Poppins-Light;
  margin: 0px;
  font-size: 14px;
  color: ${(props) => props.theme.textPrimary};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
`;
const ErorrMsg = styled.span`
  display: inline-block;
  color: red;
  font-size: 12px;
  position: absolute;
  top:0;
  left: 0;
  margin-top: -8px;
`

const CustomInputDate = styled.input`
  font-family: Poppins-Light;
  margin: 0px;
  gap: 10px;
  color: ${(props) => props.theme.textPrimary};
  font-size: 14px;
  border: none;
  background-color: transparent;
  min-width: 200px;
  text-align: left;
  color-scheme: dark;
  &:focus {
    outline: none;
  }
  ::-webkit-datetime-edit-fields-wrapper {
    background-color: ${(props) => props.theme.bgPrimary};
    border-radius: 5px;
    max-width: 102px;
    min-width: 102px;
    padding: 5px 15px 5px 5px;
  }
  ::-webkit-inner-spin-button {
    display: none;
  }
  ::-webkit-calendar-picker-indicator {
    background-color: #ff017a;
    padding: 5px 15px;
    font-size: 22px;
    border-radius: 5px;
  }
`;
const ConfigureDiv = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
  padding-top: 15px;
`; 
const CloseButton = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`
const LinkText = styled.a`
  font-size: 14px;
  color: #ff017a;
  text-decoration: underline;
`;
export {
  InnerDiv,
  SecondText,
  FirstText,
  DetailsDiv,
  CardImg,
  ModalBox,
  FirstDiv,
  PromptText,
  CustomGrid,
  InnerGrid,
  LockText1,
  LockText2,
  CustomLockBtn,
  CustomUnlockBtn,
  LockImg,
  UnlockImg,
  LockBtnText,
  UnlockBtnText,
  TimerText,
  TimerDivFull,
  TimerDivEmpty,
  WithDrawBtn,
  ApproveBtn,
  ColourText,
  SubText,
  ErorrMsg,
  CustomInputDate,
  ConfigureDiv,
  CloseButton,
  PromptImg,
  LinkText
};
