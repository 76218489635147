import styled from "@emotion/styled";
import { CardContent, Grid } from "@mui/material";
import DoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import DoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';

const Background = styled.div`
  /* z-index: 1; */
  position: relative;
  /* background-color: #63c6ff; */
  min-height: 100vh;
`;

const CustomCard = styled(CardContent)`
  padding: 20px;
  background-color: ${(props) => props.theme.bgPrimary};
  border: none;
  border-radius: 15px;
  border: 1px solid #3e3944;
`;

const HeadingText = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Medium;
  font-size: 20px;
  word-break: keep-all;
  margin: 0;
  text-align: left;
`;

const CardHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const CardFooter = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`;

const SearchTokenDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const PaginationDiv = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const SubText = styled.p`
  padding-left: 10px;
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Light;
  font-size: 15px;
  text-align: left;
  @media screen and (max-width: 899px) {
    font-size: 12px;
  }
`;
const CountText = styled.p`
  padding-left: 10px;
  font-family: Poppins-Light;
  font-size: 15px;
  text-align: left;
  color: ${(props) => props.theme.textPrimary};
  margin: 3px 30px 4px 20px;
  @media screen and (max-width: 899px) {
    font-size: 12px;
  }
`;

const CustomGrid = styled(Grid)`
  @media screen and (max-width: 599px) {
    display: none;
  }
`;

const CustomSelect = styled.select`
  background-color: transparent;
  outline: none;
  color: ${(props) => props.theme.textPrimary};
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ff017a;
  width: 180px;
  max-height: 35px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  background-image: url('./downarrow.png');
  background-size: 10%;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  @media screen and (max-width: 899px) {
    width: 180px;
  }
  @media screen and (max-width: 599px) {
    border: none;
    width: 60px;
    content-visibility: hidden;
    background-size: 50%;
  }
`;

const Option = styled.option`
  color: ${(props) => props.theme.textPrimary};
  background-color: ${(props) => props.theme.bgSecondary};
`;

const CustonInput = styled.input`
  background-color: transparent;
  padding: 5px 10px;
  font-size: 14px;
  outline: none;
  width: 100%;
  max-height: 35px;
  max-width: 170px;
  border-radius: 5px;
  border: 1px solid #ff017a;
  ::placeholder {
    color: ${(props) => props.theme.textPrimary};
  }
  @media screen and (max-width: 599px) {
    border: none;
    content-visibility: hidden;
    max-width: 20px;
  }
`;

const SearchBtn = styled.img`
  width: 100%;
  height: auto;
  max-width: 17px;
  position: absolute;
  right: 10px;
  top: 7px;
`;

const DBArrowleft = styled(DoubleArrowLeft)`
  background-color: #ff017a;
  fill: ${props => props.theme.textPrimary};
  border-radius: 5px;
  font-size: 30px;
`;
const DBArrowRight = styled(DoubleArrowRight)`
  background-color: #ff017a;
  border-radius: 5px;
  fill: ${props => props.theme.textPrimary};
  font-size: 30px;
`;

const SingleArrowRight = styled(ArrowForward)`
  background-color: #ff017a;
  fill: ${props => props.theme.textPrimary};
  border-radius: 5px;
  font-size: 30px;
  padding: 5px;
`;
const SingleArrowLeft = styled(ArrowBack)`
  background-color: #ff017a;
  fill: ${props => props.theme.textPrimary};
  border-radius: 5px;
  font-size: 30px;
  padding: 5px;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  background-color: ${(props) => props.theme.bgSecondary};
  align-items: center;
  border-radius: 5px;
`;
const LoaderGrid = styled(Grid)`
  background-color: ${props => props.theme.bgSecondary};
  border-radius: 5px;
  padding: 10px 0px;
  margin: 10px 0px;
  height: 81px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export {
  CustomCard,
  Background,
  PaginationDiv,
  DBArrowleft,
  SearchTokenDiv,
  DBArrowRight,
  CardFooter,
  CardHeader,
  HeadingText,
  SubText,
  CountText,
  CustomGrid,
  CustomSelect,
  Option,
  CustonInput,
  SearchBtn,
  CenterDiv,
  SingleArrowRight,
  SingleArrowLeft,
  LoaderGrid
};
