import { Container, Grid } from "@mui/material";
import {
  CustomCard,
  Background,
  PaginationDiv,
  SearchTokenDiv,
  CardFooter,
  CardHeader,
  HeadingText,
  SubText,
  CountText,
  CustomGrid,
  CustomSelect,
  Option,
  CustonInput,
  SearchBtn,
  DBArrowleft,
  CenterDiv,
  DBArrowRight,
  SingleArrowRight,
  SingleArrowLeft,
  LoaderGrid
} from "./styles";
import React, { useEffect } from "react";
import { Loader } from "../../Components/loader";
import Lockedpairs_Card from "../../Components/Cards/LockedPairCard";
import Footer from "../../Components/Footer";
import Menu from "../../Components/LockerMenu";
import { SectionWrapper } from "../../Styles/style";
import searchimg from "../../../assets/Images/searchimg.png";
import { usePreviewHooks } from "../../../Hooks/usePreviewHooks";

const LockedPairs = () => {
  const { noOfLocksInContract, isLoader } = usePreviewHooks()
  // console.log('test: ', noOfLocksInContract)
  let cardList = ''
  if (noOfLocksInContract !== 'No Data found.!') {
    cardList = noOfLocksInContract.map((key,index)=>{
      if(key.unlockDate !== 'Withdrew'){
        return(
          <Lockedpairs_Card
            key={index}
            pairAddress={key.lpAddress}
            Name={key.mainToken}
            Blockchain="ETH"
            Liquidity={key.lockedInPercent+"%"}
            Value={"$ "+key.lockedLP}
            Unlock={key.unlockDate}
        />
        )
      }
     
    })    
  }
  useEffect(()=>{},[noOfLocksInContract])
  return (
    <div>
      <Background>
        <Menu />
        <SectionWrapper p="3rem 0" alignItems="flex-start">
          <Container maxWidth="xl">
            <CustomCard>
              <CardHeader>
                <HeadingText>Locked Pairs</HeadingText>
                {/* <SearchTokenDiv>
                  <CustomSelect id="unilocks">
                    <Option disabled="disabled" selected>
                      Next Unilock
                    </Option>
                    <Option value="saab">Saab</Option>
                    <Option value="opel">Opel</Option>
                    <Option value="audi">Audi</Option>
                  </CustomSelect>
                  <CustonInput
                    type="text"
                    placeholder="Search Token"
                  ></CustonInput>
                  <SearchBtn src={searchimg} />
                </SearchTokenDiv> */}
              </CardHeader>
              <Grid container spacing={1}>
                <Grid item xs={4} sm={2}>
                  <SubText>Name</SubText>
                </Grid>
                <CustomGrid item xs={2}>
                  <SubText>Blockchain</SubText>
                </CustomGrid>
                <Grid item xs={4} sm={2}>
                  <SubText>Liquidity Lock</SubText>
                </Grid>
                <CustomGrid item xs={2}>
                  <SubText>Value Locked</SubText>
                </CustomGrid>
                <CustomGrid item xs={2}>
                  <SubText>Unlock Countdown</SubText>
                </CustomGrid>
                <Grid item xs={4} sm={2}>
                  <SubText>Action</SubText>
                </Grid>
              </Grid>
              {noOfLocksInContract[0]? <>{cardList}</> : null }
              {noOfLocksInContract === 'No Data found.!'? <LoaderGrid>No Data found.!</LoaderGrid> : null }
              {isLoader ? <LoaderGrid><Loader /></LoaderGrid> : null }
              {/* <CardFooter>
                <SubText>Showing Page 1 Of 10 | 10 Results</SubText>
                <PaginationDiv>
                  <DBArrowleft />
                  <CenterDiv>
                    <SingleArrowLeft />
                    <CountText>1</CountText>
                    <SingleArrowRight />
                  </CenterDiv>
                  <DBArrowRight />
                </PaginationDiv>
              </CardFooter> */}
            </CustomCard>
          </Container>
        </SectionWrapper>
        <Footer />
      </Background>
    </div>
  );
};

export default LockedPairs;
