import { Container } from "@mui/material";
import React, { useEffect } from "react";
import { HeadingText, CustomCard, Background, LoaderGrid } from "./styles";
import MyLocks_Cards from "../../Components/Cards/MyLocksCard";
import Footer from "../../Components/Footer";
import Menu from "../../Components/LockerMenu";
import { SectionWrapper } from "../../Styles/style";
import { useMyLocksHooks } from "../../../Hooks/useMyLocksHooks";
import { Loader } from "../../Components/loader";

const MyLockedPair = () => {

  const { noOfUserLocks, isLoader } = useMyLocksHooks();
  let cardList = "";
  if (noOfUserLocks !== 'No data') {
    cardList = noOfUserLocks.map((key, index) => {
      return (
        <MyLocks_Cards
          key={index}
          lpAddress={key.lpAddress}
          lpName={"Locked " + key.lpName + " - "}
          lockLP={key.lockedLP + " " + key.lpSymbol}
          lockedDate={key.lockedDate}
          unlockDate={key.unlockDate}
          unlockedAmount={key.lockedLP2}
          lpIndex={key.lpIndex}
          lpLockId={key.lpLockId}
        />
      );
    });
  }
  useEffect(()=>{},[noOfUserLocks])
  return (
    <div>
      <Background>
        <Menu />
        <SectionWrapper p="3rem 0" alignItems="flex-start">
          <Container maxWidth="xl">
            <CustomCard>
              <HeadingText>My Locks</HeadingText>
              {noOfUserLocks[0]? <>{cardList}</> : null }
              {noOfUserLocks === 'No data'? <LoaderGrid>No Data found.!</LoaderGrid> : null }
              {isLoader ? <LoaderGrid><Loader /></LoaderGrid> : null }
            </CustomCard>
          </Container>
        </SectionWrapper>
        <Footer />
      </Background>
    </div>
  );
};

export default MyLockedPair;
