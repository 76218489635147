import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const EthImg = styled.img`
  width: 100%;
  max-width: 35px;
  height: auto;
  padding-right: 5px;
  @media screen and (max-width: 899px) {
    max-width: 25px;
  }
`;

const EthLogo = styled.img`
  width: 100%;
  max-width: 15px;
  height: auto;
  padding-right: 5px;
`;

const CustomGrid = styled(Grid)`
  background-color: ${props => props.theme.bgSecondary};
  border-radius: 5px;
  padding: 10px 0px;
  margin: 10px 0px;
`;

const InnerGrid = styled(Grid)`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: left;
  @media screen and (max-width: 599px) {
    text-align: -webkit-center;
  }
`;

const CustomInnerGrid = styled(Grid)`
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: left;
  @media screen and (max-width: 599px) {
    text-align: -webkit-center;
    display: none;
  }
`;

const SubText = styled.p`
  font-family: Poppins-Light;
  margin: 0;
  color: ${props => props.theme.textPrimary};
  word-break: keep-all;
  font-size: 14px;
  text-align: left;
  @media screen and (max-width: 899px) {
    font-size: 12px;
  }
`;

const ViewButton = styled.a`
  margin: 0;
  font-family: Poppins-SemiBold;
  font-size: 14px;
  border: none;
  color: ${props => props.theme.textPrimary};
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  background-color: #ff017a;
  text-decoration: unset; 
  @media screen and (max-width: 899px) {
    font-size: 12px;
  }
`;

export {ViewButton, SubText, CustomInnerGrid, InnerGrid, CustomGrid, EthLogo, EthImg }