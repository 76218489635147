import styled from "@emotion/styled";
import { CardContent } from "@mui/material";

const Background = styled.div`
  /* z-index: 1; */
  position: relative;
  /* background-color: #63c6ff; */
  min-height: 100vh;
`;

const CustomCard = styled(CardContent)`
  background-color: ${(props) => props.theme.bgPrimary};
  padding: 20px;
  border: none;
  border-radius: 15px;
  border: 1px solid #3e3944;
`;

const HeadingText = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Medium;
  font-size: 20px;
  margin: 0px 0px 20px;
  text-align: left;
`;
const LoaderGrid = styled.div`
  background-color: ${(props) => props.theme.bgSecondary};
  border-radius: 5px;
  padding: 10px 0px;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 134px;
`;
export { HeadingText, CustomCard, Background, LoaderGrid };
