import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import loadImg from '../../../assets/Images/loader.png';
const laoderanimation = keyframes`
    from {
        transform: rotate(0);
    } to {
        transform: rotate(360deg);
    }
`
const Image = styled.img`
    height: 30px;
    width: auto;
    animation: ${laoderanimation} 0.5s linear infinite;
`
const LoaderContainer = styled.div`
    background: #11101b75;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Loader = () => {
  return (
    <div>
        <Image src={loadImg} alt="loader" />
    </div>
  )
}
