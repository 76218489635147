import { ethers } from "ethers"
import { useEffect, useState } from "react";
import { useAccount, useContractRead } from "wagmi"
import { lockerAddress,lockerAbi, LPABI, ALCHEMYKEY, NETWORK } from "../Contracts"

const useMyLocksHooks = () => {
    const { address } = useAccount();

    const [ LockerError, setHookError ] = useState(false)
    const [ isLoader, setLoader ] = useState(true)
    const [ noOfUserLocks, setNoOfUserLocks ] = useState([])

    const provider = new ethers.providers.AlchemyProvider( NETWORK , ALCHEMYKEY )
    const contract = new ethers.Contract( lockerAddress , lockerAbi, provider )

    const getUserNumLockedTokens = useContractRead({
        address: lockerAddress,
        abi: lockerAbi,
        functionName: 'getUserNumLockedTokens',
        args: [address],
        onSuccess(data) {
            searchIndex(parseInt(data._hex))
        },
        onError(error) {
            setHookError(true)
            setNoOfUserLocks('No data')
            setLoader(false)
        },
    })

    const searchIndex = async (arg) => {
        const allarr = [];
        try {
            for (let locksIndex = 0; locksIndex < arg; locksIndex++) {

                let lpAtIndex= await contract.getUserLockedTokenAtIndex (address, locksIndex); 

                
                let lpContract = new ethers.Contract( lpAtIndex , LPABI, provider )
                let lpName = await lpContract.name();
                let lpSymbol = await lpContract.symbol();


                let noOfLockUserToknen= await contract.getUserNumLocksForToken (address, lpAtIndex); 
                
                for (let tokenIndex = 0; tokenIndex < parseInt(noOfLockUserToknen._hex); tokenIndex++) {
                    let pairDetails= await contract.getUserLockForTokenAtIndex(address, lpAtIndex, tokenIndex); 

                    allarr.push({
                        lpName: lpName,
                        lpSymbol: lpSymbol,
                        lpAddress: lpAtIndex,
                        lpIndex: tokenIndex,
                        lpLockId: parseInt(pairDetails[4]._hex),
                        lockedLP: Number(parseInt(pairDetails[1]._hex)/1e18).toFixed(4), 
                        lockedLP2: pairDetails[1]._hex,
                        unlockDate: parseInt(pairDetails[3]._hex),
                        lockedDate: parseInt(pairDetails[0]._hex),
                    });
                    setNoOfUserLocks(allarr)
                }
            }
        } catch (error) {
            console.log('hooks error: ', error)
            setNoOfUserLocks('No data')
            setHookError(true)
            setLoader(false)
        }
        setLoader(false)
        if(allarr.length === 0) setNoOfUserLocks('No data')
    }
    return { noOfUserLocks, isLoader, LockerError }
}

export {useMyLocksHooks}