import styled from "@emotion/styled";

const CustomCard = styled.div`
  text-align: center;
  background-image: ${props => props.theme.gradientCard};
  padding: 10px;
  max-width: 380px;
  border: none;
  border-radius: 10px;
`;

const ValueText = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 25px;
  color: ${props => props.theme.textPrimary};
  margin: 0px;
  min-height: 36px;
`;

const TokenText = styled.p`
  color: #fe1f71;
  font-family: Poppins-Medium;
  font-size: 18px;
  margin: 5px 0px 10px;
`;

export { CustomCard, ValueText, TokenText };
