import { ethers } from "ethers"
import { useEffect, useState } from "react";
import { useAccount, useContractRead, useToken } from "wagmi"
import { LPABI } from "../Contracts"

const usePair = (add) => {
    const { address } = useAccount()
    const [ hookError, setHookError ] = useState(false)
    const [ pair, setPair ] = useState('')
    const [ pairDecimals, setPairDecimals ] = useState('')
    const [ pairTokenA, setPairTokenA ] = useState('')
    const [ tokenAName, setTokenAName ] = useState('')
    const [ pairTokenB, setPairTokenB ] = useState('')
    const [ tokenBName, setTokenBName ] = useState('')
    const [ integerBalance, setIntegerBalance ] = useState('')
    const [ formatedBalance, setFormatedBalance ] = useState('')

    useEffect(()=>{},[formatedBalance,integerBalance])
    const pairS = useContractRead({
        address: add,
        abi: LPABI,
        functionName: 'name',
        onSuccess(data) {
            setPair(data)
        },
        onError(error) {
            setHookError(true)
        },
    })
    const pairDecimalsS = useContractRead({
        address: add,
        abi: LPABI,
        functionName: 'decimals',
        onSuccess(data) {
            setPairDecimals(data)
        },
        onError(error) {
            setHookError(true)
        },
    })
    const pairTokenAS = useContractRead({
        address: add,
        abi: LPABI,
        functionName: 'token0',
        onSuccess(data) {
            setPairTokenA(data)
        },
        onError(error) {
            setHookError(true)
        },
    })
    const tokenANameS = useToken({ 
        address: pairTokenAS.data,
        suspense: true,
        staleTime: 2_000,
        onSuccess(data) {
            setTokenAName(data.symbol)
        },
        onError(error) {
            setHookError(true)
        },
    })

    const pairTokenBS = useContractRead({
        address: add,
        abi: LPABI,
        functionName: 'token1',
        onSuccess(data) {
            setPairTokenB(data)
        },
        onError(error) {
            setHookError(true)
        },
    })
    const tokenBNameS = useToken({ 
        address: pairTokenBS.data,
        suspense: true,
        staleTime: 2_000,
        onSuccess(data) {
            setTokenBName(data.symbol)
        },
        onError(error) {
            setHookError(true)
        },
    })
    
    const lpBalance = useContractRead({
        address: add,
        abi: LPABI,
        functionName: 'balanceOf',
        args: [address],
        onSuccess(data) {
            setIntegerBalance(ethers.utils.formatEther(data['_hex']))
            setFormatedBalance(() => Number(integerBalance).toFixed(4))
        },
        onError(error) {
            setIntegerBalance('0.00')
            setHookError(true)
        },
    })

    return { pair, pairDecimals, pairTokenA, tokenAName, pairTokenB, tokenBName, integerBalance, formatedBalance, hookError }
}

export {usePair}