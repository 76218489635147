import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import logo from "../../../../assets/Images/logo2.png";
import { DesktopMainMenu, MaterialUISwitch, MenuLink, LogoText, LogoDiv } from "../styles";
import { toggleTheme } from '../../../../Redux/switchTheme'
import { useDispatch, useSelector } from "react-redux";
import CustomWeb3Button from "../../Web3Button";

const DesktopMenu = (props) => {
  const currentTheme = useSelector( (state) => state.LDTheme.value )
  const dispatch = useDispatch();
 
  return (
    <DesktopMainMenu maxWidth="xl" disableGutters={true}>
      <MenuLink href="/" p="0px">
        <LogoDiv>
          <img src={logo} width="50" height='55' alt="Logo" />
          <LogoText>UNI LOCK</LogoText>
        </LogoDiv>
      </MenuLink>
      <div>
        {props.menuList.map((value, i) => <MenuLink key={i} href={value.link} target={value.target} className={value.customClass}>{value.title}</MenuLink> )}
        {/* <FormControlLabel
          control={
            <MaterialUISwitch 
              sx={{ m: 1 }} 
              checked={currentTheme}
            />
          }
          onClick={() => dispatch(toggleTheme())}
        /> */}
      </div>
      <CustomWeb3Button />
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
