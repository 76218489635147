import styled from "@emotion/styled";

const PageWrapper = styled.div`
  /* background-color: ${(props) => props.theme.pageColor}; */
  min-height: 100vh;
`;
const SectionWrapper = styled.div`
  min-height: ${(props) => (props.mh ? props.mh : "calc(100vh - 158px)")};
  padding: ${(props) => (props.p ? props.p : "0px 0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: -webkit-center;
  text-align: -moz-center;
  display: flex;
  align-items: ${props => (props.alignItems ? props.alignItems : "center")};
  @media screen and (max-width:899px) {
    padding: 20px 0px;
  }
`;
const P = styled.p`
  font-family: "Poppins-Regular";
  font-size: ${(props) => (props.fs ? props.fs : "16px")};
  color: ${props => props.theme.textPrimary};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  padding: ${(props) => (props.p ? props.p : "20px 0")};
  margin: 0;
  @media (max-width: 1100px) {
    font-size: 14px;
    max-width: 100%;
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
`;
const CustomContainer = styled.div`
  max-width: 2600px;
`;
const SectionHeading = styled.h2`
  text-shadow: 2px, 1px, #fff;
  text-align: center;
  color: #fff;
  font-size: ${(props) => (props.fs ? props.fs : "200px")};
  cursor: pointer;
  background-image: linear-gradient(
    to top,
    #59311a,
    #59311a,
    #fce2a8,
    #59311a,
    #59311a
  );
  color: #59311a;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: ${(props) => (props.ls ? props.ls : "20px")};
  font-family: "ARCADECLASSIC";

  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  @media (max-width: 899px) {
    padding: 50px 0 0 0;
    font-size: 70px;
  }
  @media (max-width: 599px) {
    letter-spacing: 0px;
    font-size: 60px;
    /* padding: ${(props) => (props.P599 ? props.p599 : "0px 0")};
    line-height: unset;
    font-size: 40px; */
  }
`;
const SectionSubHeading = styled.h3`
  text-align: center;
  color: #fff;
  font-size: ${(props) => (props.fs ? props.fs : "200px")};
  cursor: pointer;
  background-image: linear-gradient(to top, #59311a, #59311a);
  color: #59311a;
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: ${(props) => (props.ls ? props.ls : "20px")};
  font-family: "ARCADECLASSIC";
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  @media (max-width: 899px) {
    font-size: 50px;
  }
  @media (max-width: 599px) {
    padding: ${(props) => (props.P599 ? props.p599 : "0px 0")};
    line-height: unset;
    font-size: 40px;
  }
`;
const SectionSmallHeading = styled.h3`
  font-family: "Poppins-Regular";
  font-size: ${(props) => (props.fs ? props.fs : "60px")};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.p ? props.p : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;
export {
  PageWrapper,
  SectionWrapper,
  P,
  SectionHeading,
  SectionSubHeading,
  CustomContainer,
  SectionSmallHeading,
};
