import styled from "@emotion/styled";
import { Box, CardContent } from "@mui/material";

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.bgPrimary};
  border: 1px solid #3e3944;
  box-shadow: 24;
  border-radius: 15px;
  outline: none;
  text-align: center;
  padding: 10px 20px;
`;

const Background = styled.div`
  /* z-index: 1; */
  position: relative;
  /* background-color: #63c6ff; */
  min-height: 100vh;
`;
const CustomCard = styled(CardContent)`
  background-color: ${(props) => props.theme.bgPrimary};
  padding: 20px;
  margin-top: 40px;
  max-width: 500px;
  border: none;
  border-radius: 15px;
  border: 1px solid #3e3944;
`;

const LockImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 25px;
`;

const ImgText = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Medium;
  font-size: 18px;
  margin: 10px 0px 15px;
`;
const LowerText = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Light;
  font-size: 14px;
  margin: 25px 0px 15px;
`;

const DetailsDiv = styled.div`
  background-color: ${(props) => props.theme.bgSecondary};
  padding: 5px 10px;
  margin: 0px 0px;
  border: none;
  border-radius: 5px;
`;

const LockDiv = styled.div`
  padding: 5px 10px;
  margin: 0px;
`;

const InnerDiv = styled.div`
  position: relative;
  justify-content: space-between;
  display: flex;
  margin: 10px 0px;
  align-items: center;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`;

const FirstText = styled.p`
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-Light;
  color: #828384;
  margin: 0px;
  font-size: 14px;
`;

const SecondText = styled.p`
  font-family: Poppins-Light;
  margin: 0px;
  font-size: 14px;
  color: ${(props) => props.theme.textPrimary};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
`;
const PinkText = styled(SecondText)`
  color: #ff017a;
  font-weight: 600;
`;

const ApproveBtn = styled.button`
  padding: 8px 10px;
  color: ${(props) => props.theme.textPrimary};
  background-color: #ff017a;
  font-family: Poppins-SemiBold;
  border-radius: 5px;
  display: block;
  text-decoration: none;
  margin: ${(props) => (props.margin ? props.margin : "0px")};
  width: 100%;
  cursor: pointer;
`;

const EditableText = styled.input`
  font-family: Poppins-Light;
  margin: 0px;
  font-size: 14px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.bgPrimary};
  padding: 3px 10px 3px;
  border: none;
  max-width: 120px;
  text-align: left;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${(props) => props.theme.textPrimary};
  }
`;
const CustomInputDate = styled.input`
  font-family: Poppins-Light;
  margin: 0px;
  gap: 10px;
  color: ${(props) => props.theme.textPrimary};
  font-size: 14px;
  border: none;
  background-color: transparent;
  min-width: 190px;
  text-align: left;
  color-scheme: dark;
  background-image: url(./Images/calender.png);
  background-position: right;
  &:focus {
    outline: none;
  }
  ::-webkit-datetime-edit-fields-wrapper{
    background-color: ${(props) => props.theme.bgPrimary};
    border-radius: 5px;
    max-width: 102px;
    min-width: 102px;
    padding: 5px 15px 5px 5px;
  }
  ::-webkit-calendar-picker-indicator {
    background-color: #ff017a;
    padding: 3px 15px;
    font-size: 22px;
    border-radius: 5px;
  }
`;

const CustomInputTime = styled.input`
  font-family: Poppins-Light;
  margin: 0px;
  gap: 10px;
  font-size: 14px;
  border: none;
  background-color: transparent;
  text-align: left;
  color-scheme: dark;
  min-width: 190px;
  background-image: url(./Images/calender.png);
  background-position: right;
  color: ${(props) => props.theme.textPrimary};
  &:focus {
    outline: none;
  }
  ::-webkit-datetime-edit-fields-wrapper {
    background-color: ${(props) => props.theme.bgPrimary};
    border-radius: 5px;
    padding: 5px 50px 5px 5px;
  }
  ::-webkit-calendar-picker-indicator {
    background-color: #ff017a;
    padding: 3px 13px;
    font-size: 22px;
    border-radius: 5px;
  }
  ::-webkit-datetime-edit-ampm-field {
    border-radius: 5px;
    color: ${(props) => props.theme.textPrimary};
    padding: 5px 0px;
  }
`;
const CustomInput = styled(CustomInputTime)`
  background-color: ${(props) => props.theme.bgPrimary};
  padding: 3px 13px;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
`


const Custombutton = styled.button`
  font-family: Poppins-Light;
  min-width: 60px;
  color: ${(props) => props.theme.textPrimary};
  background-color: #ff017a;
  border: none;
  font-size: 14px;
  border-radius: 5px;
  align-items: center;
`;

const ConfigureDiv = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  &.divWidth{
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: fill-available;
  }
`;
const ErorrMsg = styled.span`
  display: inline-block;
  color: red;
  font-size: 12px;
  position: absolute;
  top:0;
  left: 0;
  margin-top: -8px;
`
const PromptImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;
  margin: 15px 0px;
`;
const PromptText = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.textPrimary};
  margin: 0px;
`;
const LinkText = styled.a`
  font-size: 14px;
  color: #ff017a;
  text-decoration: underline;
`;

export {
  LowerText,
  ImgText,
  LockImg,
  CustomCard,
  Background,
  LinkText,
  PromptText,
  PromptImg,
  ConfigureDiv,
  Custombutton,
  CustomInputTime,
  CustomInput,
  CustomInputDate,
  EditableText,
  ApproveBtn,
  SecondText,
  FirstText,
  InnerDiv,
  LockDiv,
  DetailsDiv,
  PinkText,
  ModalBox,
  ErorrMsg
};
