import {ViewButton, SubText, CustomInnerGrid, InnerGrid, CustomGrid, EthLogo, EthImg} from "./styles";
import ethimg from "../../../../assets/Images/ethimg.png";
import ethlogo from "../../../../assets/Images/ethlogo.png";

const Lockedpairs_Card = (props) => {
  return (
    <CustomGrid container>
      <InnerGrid item xs={4} sm={2}>
        <EthImg src={ethimg} />
        <SubText>{props.Name}</SubText>
      </InnerGrid>
      <CustomInnerGrid item xs={2}>
        <EthLogo src={ethlogo} />
        <SubText>{props.Blockchain}</SubText>
      </CustomInnerGrid>
      <InnerGrid item xs={4} sm={2}>
        <SubText>{props.Liquidity}</SubText>
      </InnerGrid>
      <CustomInnerGrid item xs={2}>
        <SubText>{props.Value}</SubText>
      </CustomInnerGrid>
      <CustomInnerGrid item xs={2}>
        <SubText>{props.Unlock}</SubText>
      </CustomInnerGrid>
      <InnerGrid item xs={4} sm={2}>
        <ViewButton href={'/detail?pair='+props.pairAddress}>View</ViewButton>
      </InnerGrid>
    </CustomGrid>
  );
};

export default Lockedpairs_Card;