import styled from "@emotion/styled";
import { Box } from "@mui/material";

const Background = styled.div`
  /* z-index: 1; */
  position: relative;
  /* background-color: #63c6ff; */
  min-height: 100vh;
`;
const WhaleImage = styled.div`
  text-align: -webkit-center;
`;
const LogoHeading = styled.p`
  font-size: 40px;
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-SemiBold;
  margin: 0px;
  @media screen and (max-width:899px){
    font-size: 30px;
  }
`;

const TwoButtons = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (max-width: 599px) {
    max-width: fit-content;
  }
`;

const FirstBtn = styled.a`
  text-decoration: none;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: ${(props) => props.theme.textPrimary};
  background-color: #ff017a;
  border-radius: 10px;
  padding: 7px 30px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  align-self: center;
  @media screen and (max-width: 599px) {
    padding: 7px 20px;
  }
  @media screen and (max-width: 399px) {
    font-size: 13px;
  }
`;
const SecondBtn = styled.a`
  margin-left: 10px;
  text-decoration: none;
  align-items: center;
  display: flex;
  cursor: pointer;
  color: #ff017a;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 7px 40px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  align-self: center;
  @media screen and (max-width: 599px) {
    padding: 7px 20px;
  }
  @media screen and (max-width: 399px) {
    font-size: 13px;
  }
`;

const LockImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 180px;
  padding-top: 50px;
  min-height: 0;
  @media screen and (max-width: 1200px) {
    max-width: 150px;
  }
`;

const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  width: 80%;
  max-width: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${(props) => props.theme.bgPrimary};
  border: 1px solid #3e3944;
  box-shadow: 24;
  border-radius: 15px;
  outline: none;
  text-align: center;
  padding: 10px 20px;
`;

const PromptImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 100px;
  margin: 15px 0px;
`;

const PromptText = styled.p`
  font-size: 30px;
  color: ${(props) => props.theme.textPrimary};
  margin: 0px;
`;

const ApproveBtn = styled.a`
  padding: 8px 10px;
  color: ${(props) => props.theme.textPrimary};
  background-color: #ff017a;
  font-family: Poppins-SemiBold;
  border-radius: 5px;
  display: block;
  text-decoration: none;
  cursor: pointer;
  margin: ${(props) => (props.margin ? props.margin : "0px")};
`;

export {
  ApproveBtn,
  LogoHeading,
  LockImage,
  PromptImg,
  PromptText,
  SecondBtn,
  FirstBtn,
  TwoButtons,
  WhaleImage,
  Background,
  ModalBox,
};
