import styled from "@emotion/styled";
import { Container } from "@mui/material";
import React from "react";
import { P } from "../../Styles/style";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";

const CustomTwitterIcon = styled(TwitterIcon)`
  fill: ${(props) => props.theme.textPrimary};
`;

const CustomTelegramIcon = styled(TelegramIcon)`
  fill: ${(props) => props.theme.textPrimary};
`;

const Footer = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "20px 10px",
        alignItems: "center",
      }}
    >
      <div>
        <P align="" p="0" m="0">
          Copyright © 2022 Unilock.finance
        </P>
      </div>
      <div>
        <a href="https://t.me/Unilocketh">
          <CustomTelegramIcon />
        </a>
        &nbsp;&nbsp;&nbsp;
        <a href="https://twitter.com/Unilocketh">
          <CustomTwitterIcon />
        </a>
        {/* <SocialText>Twitter</SocialText> */}
        {/* <SocialText>Medium</SocialText> */}
      </div>
    </Container>
  );
};

export default Footer;
