import { PairImg, SearchAddressDiv, DetailsDiv, InnerDiv, PairDiv, FirstText, SecondText, ContinueBtn, ContinueBtnDisable } from '../styles'
import tokenimg from '../../../../assets/Images/tokenimg.png'
import { Loader } from '../../../Components/loader'
import Errors from '../../../Components/Errors'
import { usePair } from '../../../../Hooks/useLpHooks'

const CardPair =  (props) => {
    const { pair, pairDecimals, pairTokenA, tokenAName, pairTokenB, tokenBName, integerBalance, hookError } = usePair(props.address)
    
    return(
        <>
        {pair ?
            <SearchAddressDiv>
                <DetailsDiv>
                    <InnerDiv>
                        <FirstText>Name</FirstText>
                        <SecondText>{pair}</SecondText>
                        </InnerDiv>
                        <InnerDiv>
                        <FirstText>Pair</FirstText>
                        <PairDiv>
                            {pairTokenA ?
                                <SecondText>
                                    <span title={pairTokenA}>{tokenAName}</span> / <span title={pairTokenB}>{tokenBName}</span>
                                    <PairImg src={tokenimg} />
                                </SecondText>
                            :
                                null
                            }
                        </PairDiv>
                        </InnerDiv>
                        <InnerDiv>
                        <FirstText>Balance</FirstText>
                        <SecondText>{Number(integerBalance).toFixed(3) }</SecondText>
                        </InnerDiv>
                        <InnerDiv>
                        <FirstText>Decimals</FirstText>
                        <SecondText>{pairDecimals}</SecondText>
                    </InnerDiv>
                </DetailsDiv>
                {Number(integerBalance) <= 0 ?
                    <ContinueBtnDisable disabled={true}>Continue</ContinueBtnDisable>
                :
                    <ContinueBtn href={`/lock-lp?pair=${props.address}`} >Continue</ContinueBtn>
                }
            </SearchAddressDiv>
            :
            <>
                {hookError ?
                    <Errors type="Invalid LP pair.!" />
                    :
                    <DetailsDiv>
                        <Loader />
                    </DetailsDiv>
                }
            </>
        }
        </> 
    )
}

  export default CardPair;