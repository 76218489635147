import React, { useEffect, useRef, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { borderRadius } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  background:'rgb(44, 34, 99)',
  border:'1px solid rgb(44, 34, 99)',
  borderRadius:'20px'
  
};

export default function BasicModal() {
    // const [state, setState] = useState({ Hash });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
//   const [alwaysOpen, setalwaysOpen] = useState(true);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Create</Button> */}
      <Modal
        open={handleOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography className='' id="modal-modal-title" variant="h6" component="h2" style={{textAlign:'center',color:'#ffffff'}}>
          </Typography>
          <Typography className='py-2' id="modal-modal-title" variant="h6" component="h2" style={{textAlign:'center',color:'#ffffff'}}>
            Wrong Wallet connected
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
