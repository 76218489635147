import styled from "@emotion/styled"
import { useWeb3Modal } from "@web3modal/react"
import { useEffect, useState } from "react"
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi"
import { Loader } from "../loader"
import { ConnectBTN } from "../Web3Button/style"

const CustomBtn = styled(ConnectBTN)`
    width: 100%;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;

    button{
        width: 100%;
        height: 44px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:disabled,
        &[disabled]{
        border: 1px solid ${props=> props.theme.btnborderDisable};
        background-color: ${props=> props.theme.btnDisable};
        color: ${props=> props.theme.btnTxtDisable};
        cursor: no-drop;
        }
    }
`
const CustomButton = (props) => {
    const {isAllowance, allowanceCall, isLock, lockCall, loading} = props;
    const [ chainId, setChainId ] = useState('0')
    const { open } = useWeb3Modal()
    const { chain } = useNetwork()
    const { isDisconnected } = useAccount()
    const { switchNetwork } = useSwitchNetwork()

    useEffect(()=>{
        if (chain) { setChainId(chain.id) }
    },[chainId, chain])
    if (isDisconnected) {
        return <CustomBtn>
                <button onClick={() => open()}>Connect Wallet</button>
            </CustomBtn>
    } else if(chainId != 1 && chainId != 5) {
        return <CustomBtn>
                <button onClick={() => switchNetwork(1)}>Switch Network</button>
            </CustomBtn>
    } else if(!isAllowance) {
        return <CustomBtn>
                {!loading?
                    <button onClick={() => allowanceCall()}>Approve</button>
                    :
                    <button disabled={loading}><Loader /></button>
                }
            </CustomBtn>
    } else {
        return <CustomBtn>
                {!loading?
                    <button disabled={!isLock} onClick={() => lockCall()}>Lock</button>
                    :
                    <button disabled={loading}><Loader /></button>
                }
            </CustomBtn>
    }
    
}

export default CustomButton