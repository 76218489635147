import {
  Text,
  LowerText,
  ImgText,
  SearchImg,
  TokenImg,
  LockImg,
  CustomCard,
  Background,
  TokenDivEth,
  TokenDivSwap,
  InputDiv,
  CustomInput,
} from './styles'
import { Container } from "@mui/system";
import React from "react";
import Menu from "../../Components/LockerMenu/index";
import { useState } from "react";
import { SectionWrapper } from "../../Styles/style";
import Footer from "../../Components/Footer";
import lockerimg from '../../../assets/Images/lock.png'
import swapimg from '../../../assets/Images/swapimg.png'
import ethimg from '../../../assets/Images/ethimg.png'
import searchimg from '../../../assets/Images/searchimg.png'
import Errors from '../../Components/Errors';
import CardPair from './Card';
import Loader from '../../Components/loader';
const {Suspense} = require('react');

const LockerHome = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [ inputVal, setInputVal] = useState('')
  const [ useloader, setloader] = useState(false)


  const handleSearchAddress = (e) => {
    let val = e.target.value
    setInputVal(val)
    if (val.length == 42 && val.slice(0, 2) == '0x') {
      setloader(true)
      setShowDetails(true)
      setSearchError(false)
    }else{
      setShowDetails(false)
      setSearchError(true)
    }

  };

  return (
    <div>
       <Suspense>
      <Background>
        <Menu />
        <SectionWrapper p="1rem 0 0 0" alignItems="flex-start">
          <Container maxWidth="xl">
            <CustomCard>
              <LockImg src={lockerimg} />
              <ImgText>Liquidity Locker</ImgText>
              <LowerText>Network</LowerText>
              <TokenDivEth>
                <TokenImg src={ethimg} />
                <Text>Ethereum Miannet</Text>
              </TokenDivEth>
              <LowerText>Liquidity Created On</LowerText>
              <TokenDivSwap>
                <TokenImg src={swapimg} />
                <Text>Uniswap</Text>
              </TokenDivSwap>
              <LowerText>
                Enter The Pair Address You Would Like To Lock Liquidity For
              </LowerText>
              <InputDiv>
                <CustomInput
                  id="address"

                  placeholder="Token name or address...."
                  onChange={(event)=>handleSearchAddress(event)}
                  value={inputVal}
                />
                <SearchImg src={searchimg} alt="search" />
              </InputDiv>
              {useloader}
              { showDetails ? <CardPair address={inputVal} /> : null }
              { searchError ? <Errors type='Invalid LP pair.!' /> : null }
            </CustomCard>
            
          </Container>
        </SectionWrapper>
        <Footer />
      </Background>
      </Suspense>
    </div>
  );
};


export default LockerHome;
