import { ethers } from "ethers"
import { useEffect, useState } from "react";
import { useAccount, useContractRead } from "wagmi"
import { lockerAddress,lockerAbi, LPABI, ALCHEMYKEY, NETWORK } from "../Contracts"

const usePreviewHooks = (lpArg='') => {
    const { address } = useAccount();
    const [ LockerError, setHookError ] = useState(false)
    const [ isLoader, setLoader ] = useState(true)
    const [ noOfLocksInContract, setnoOfLocksInContract ] = useState([])
    const [ noOfProjects, setNoOfProjects ] = useState(0)
    const [ getInvestment, setinvestment ] = useState(0)

    const provider = new ethers.providers.AlchemyProvider( NETWORK , ALCHEMYKEY )
    
    const contract = new ethers.Contract( lockerAddress , lockerAbi, provider )
    
    const searchAtIndex = async () => {
        let totalInvestment = 0
        const allarr = [];
        try {
            let indArg= await contract.getNumLockedTokens(); 
            indArg = parseInt(indArg['_hex'])
                setNoOfProjects(indArg)
            let setIndArg = indArg;
            if(lpArg != '') setIndArg = 1;
                for (let locksIndex = 0; locksIndex < setIndArg; locksIndex++) {
                    let lpAtIndex= await contract.getLockedTokenAtIndex(locksIndex); 
                    if(lpArg != '') lpAtIndex = lpArg;

                    let lpContract = new ethers.Contract( lpAtIndex , LPABI, provider )
                    let lpReserve = await lpContract.getReserves();
                    let totalSupply = await lpContract.totalSupply();
                    totalSupply = totalSupply/1e18;

                    // token 1 
                    let token0Add = await lpContract.token0();
                    
                    let tkn0Contract = new ethers.Contract( token0Add , LPABI, provider )
                    
                    let token0sym = await tkn0Contract.symbol();
                    let token0decmals = await tkn0Contract.decimals();
                    let token0dec = '1e'+token0decmals
                    let lpReserve0 = parseInt(lpReserve._reserve0._hex)/token0dec

                    // token 2
                    let token1Add = await lpContract.token1();
                    
                    let tkn1Contract = new ethers.Contract( token1Add , LPABI, provider )
                    
                    let token1sym = await tkn1Contract.symbol();
                    let token1dec = await tkn1Contract.decimals();
                    token1dec = '1e'+token1dec
                    let lpReserve1 = parseInt(lpReserve._reserve1._hex)/token1dec;

                    if(lpReserve0 < lpReserve1){
                        lpReserve0 *= 2
                        totalInvestment += lpReserve0
                    }else{
                        lpReserve1 *= 2
                        totalInvestment += lpReserve1
                    }

                    let noOfTokenLocks = await contract.getNumLocksForToken(lpAtIndex);
                    const dateArr = [];
                    let countLP = 0, expireDate = '', expireDateInt = "";
                    for (let tokenIndex = 0; tokenIndex < noOfTokenLocks; tokenIndex++) {
                        let tokenLocks = await contract.tokenLocks(lpAtIndex, tokenIndex);

                        // getting total lp Locked
                        let lockedToken = parseInt(tokenLocks.amount['_hex'])/1e18;
                        countLP += lockedToken;

                        // getting bigger unlockDate
                        let dates = parseInt(tokenLocks.unlockDate['_hex']);
                        expireDateInt = dates;
                        dateArr.push(dates);
                        expireDate = getDateFormate(Math.max(...dateArr), countLP)
                        
                    }

                    let mainTokenName =token0sym;
                    if (token0sym == 'USDT' || token0sym == 'USDC' || token0sym == 'ETH') {
                        mainTokenName = token1sym;
                    }
                    allarr.push({
                        lpAddress: lpAtIndex,
                        lockedLP: Number(countLP).toFixed(4), 
                        lpSupply: Number(totalSupply).toFixed(4),
                        unlockDate: expireDate,
                        unlockDateInt: expireDateInt,
                        mainToken: mainTokenName, 
                        token1Add: token0Add,
                        token2Add: token1Add,
                        token1Name: token0sym, 
                        token2Name: token1sym,
                        lockedInPercent: Number((countLP/totalSupply)*100).toFixed(2)
                    });
                    setnoOfLocksInContract(allarr)
                }
            
            } catch (error) {
                console.log('hooks error: ', error)
                setnoOfLocksInContract('No Data found.!')
                setHookError(true)
                setLoader(false)
            }
            setinvestment('$ '+(totalInvestment).toFixed())
            setLoader(false)
            if (allarr.length === 0) { setnoOfLocksInContract('No Data found.!') } 
        }
        useEffect(()=>{
            searchAtIndex()
        },[])
        const getDateFormate = (arg, lp) => {
            let currentDate = new Date().getTime()/1000;
            let dateDifference = Math.abs(arg - currentDate);
        
            // days Calculate
            if (arg - currentDate > 0) {
                let days = Math.floor(dateDifference / 86400);
                dateDifference -= days * 86400;
                // calculate (and subtract) whole hours
                let hours = Math.floor(dateDifference / 3600) % 24;
                dateDifference -= hours * 3600;
                // calculate (and subtract) whole minutes
                let minutes = Math.floor(dateDifference / 60) % 60;
                dateDifference -= minutes * 60;
                // what's left is seconds
                let seconds = Math.floor(dateDifference % 60); 
                
                if ( days!= 0 ) return "in "+days+" Days";
                if ( hours!= 0 ) return "in "+hours+" Hours";
                if ( minutes!= 0 ) return "in "+minutes+" Min";
                if ( seconds!= 0 ) return "in "+seconds+" Sec";
            } else{
                if(lp <= 0){
                    return("Withdrew")
                }else{
                    return("Count Down end")
                }
            }
        }
    return { noOfLocksInContract, searchAtIndex, noOfProjects, getInvestment, isLoader, LockerError }
    
}

export {usePreviewHooks}