import { ethers } from "ethers"
import { useEffect, useState } from "react";
import { useAccount, useContractRead } from "wagmi"
import { lockerAddress,lockerAbi, LPABI } from "../Contracts"

const useLocker = (lpAddress) => {
    const { address } = useAccount();
    const [ LockerError, setHookError ] = useState(false)
    const [ lockFees, setLockFees ] = useState('')
    const [ isAllowance, setAllowance ] = useState('')

    const lockFeesH = useContractRead({
        address: lockerAddress,
        abi: lockerAbi,
        functionName: 'gFees',
        onSuccess(data) {
            setLockFees(Number(ethers.utils.formatEther(data.ethFee['_hex'])).toFixed(2))
        },
        onError(error) {
            setHookError(true)
        },
    })
    const allownceH = useContractRead({
        address: lpAddress,
        abi: LPABI,
        functionName: 'allowance',
        args: [ address, lockerAddress],
        onSuccess(data) {
            setAllowance(ethers.utils.formatEther(data['_hex']))
        },
        onError(error) {
            setHookError(true)
        },
    })
 
    return { lockFees, isAllowance, LockerError }
}

export {useLocker}