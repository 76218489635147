import styled from "@emotion/styled"

const ErrorContainer = styled.div`
    background-color: rgb(183, 0, 0, 0.5);
    border: 1px solid #b70000;
    border-radius: 6px;
    padding: 6px 10px;
    margin: 20px 0;
    font-weight: 500;
`

const Errors = (props) => {
  return (
    <ErrorContainer>{props.type}</ErrorContainer>
  )
}

export default Errors