import React, { useEffect, useState } from "react";
import { Modal } from "@mui/material";
import { useAccount, useContractWrite } from "wagmi";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import cardlock from "../../../../assets/Images/cardlock.png";
import cardunlock from "../../../../assets/Images/cardunlock.png";
import mylockscardimg from "../../../../assets/Images/mylockscardimg.png";

import logo2 from "../../../../assets/Images/logo2.png";
import { lockerAbi, lockerAddress, SCANNER } from "../../../../Contracts";
import { useDateFormate } from "../../../../Hooks/useDateFormate";
import {
  InnerDiv,
  SecondText,
  FirstText,
  DetailsDiv,
  CardImg,
  ModalBox,
  FirstDiv,
  PromptText,
  CustomGrid,
  InnerGrid,
  LockText1,
  LockText2,
  CustomLockBtn,
  CustomUnlockBtn,
  LockImg,
  UnlockImg,
  LockBtnText,
  UnlockBtnText,
  TimerText,
  TimerDivFull,
  TimerDivEmpty,
  WithDrawBtn,
  ApproveBtn,
  ColourText,
  CloseButton,
  SubText,
  ErorrMsg,
  CustomInputDate,
  ConfigureDiv,
  PromptImg,
  LinkText
} from "./styles";

const MyLocks_Cards = (props) => { //unlockedAmount lpIndex lpLockId
  const { address, isConnected, isDisconnected } = useAccount();

  const [epochTime, setEpochTime] = useState(false);
  const [inputDateError, setInputDateError] = useState(false);
  const [inputDateErrorBTN, setInputDateErrorBTN] = useState(true);
  const [isWithdraw, setIsWithdraw] = useState(true);
  const [inputDate, setInputDate] = useState(''); 
  const [trx, setTrx] = useState(''); 


  const [open, setOpen] = useState(false);
  const handleOpen = (v='') => {
    setOpen(true)
    if(v !== '') {setIsWithdraw(false)}else{setIsWithdraw(true)}
  }
  const handleClose = () => setOpen(false);
  
  const { getRmainingDays, setRemainingDays, getDateFormate, setDateFormate, getUnlockDateFormate, setUnlockDateFormate } = useDateFormate();
  const [lockedDates, setLockedDates] = useState('');
  
  // const provider = new ethers.providers.AlchemyProvider( NETWORK , ALCHEMYKEY )
  // const contract = new ethers.Contract( lockerAddress , lockerAbi, provider )

  const withdrawFunc = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: lockerAddress,
    abi: lockerAbi,
    functionName: 'withdraw',
    args: [
      props.lpAddress, //LP to be withdraw
      props.lpIndex, // index
      props.lpLockId, // lock Id
      props.unlockedAmount, // amount
    ],
    onSuccess(data) {
      setTrx(data.hash)
      handleOpen()
    },
    onError(error) {
      console.log('Withdraw error', error)
    },
  })
  const reLock = useContractWrite({
    mode: 'recklesslyUnprepared',
    address: lockerAddress,
    abi: lockerAbi,
    functionName: 'relock',
    args: [
      props.lpAddress, //LP to be withdraw
      props.lpIndex, // index
      props.lpLockId, // lock Id
      epochTime, // unlock date
    ],
    onSuccess(data) {
      setTrx(data.hash)
      handleOpen()
    },
    onError(error) {
      console.log('Withdraw error', error)
    },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingDays(props.unlockDate);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRmainingDays]);
  useEffect(() => {
    setDateFormate(props.lockedDate);
    setUnlockDateFormate(props.unlockDate);
    setLockedDates(
      "Locked " + getDateFormate + " - Unlocked " + getUnlockDateFormate
    );
  }, [getDateFormate]);

  const handleDate = (e) => {
    let date = e.target.value
    let formatedDate = new Date(date).getTime()
    let currentTime = new Date()
    
    let combinetime  = new Date(date).getTime()
    setEpochTime(combinetime/1000)
    setInputDate(date)
    if (formatedDate < currentTime) {
      setInputDateError(true)
      setInputDateErrorBTN(true)
      return false;
    }
    setInputDateError(false)
    setInputDateErrorBTN(false)
  }
  return (
    <CustomGrid container alignItems="center">
      <InnerGrid item xs={12} md={5} lg={6} display="flex" alignItems="center">
        <CardImg src={mylockscardimg} />
        <FirstDiv>
          <LockText1>{props.lpName}{props.lockLP}</LockText1>
          <LockText2>{lockedDates}</LockText2>
          {getRmainingDays !== "Count Down end" ? (
            <CustomLockBtn>
              <LockImg src={cardlock} />
              <LockBtnText>Locked</LockBtnText>
            </CustomLockBtn>
          ) : (
            <CustomUnlockBtn>
              <UnlockImg src={cardunlock} />
              <UnlockBtnText>Unlocked</UnlockBtnText>
            </CustomUnlockBtn>
          )}
        </FirstDiv>
      </InnerGrid>
      <InnerGrid item xs={12} md={3} lg={3} className="countdown">
        <LockText1>Unlock Countdown</LockText1>
        {getRmainingDays !== "Count Down end" ? (
          <TimerDivFull />
        ) : (
          <TimerDivEmpty />
        )}
        <TimerText>{getRmainingDays}</TimerText>
      </InnerGrid>
      <InnerGrid item xs={12} md={4} lg={3}>
        <WithDrawBtn onClick={()=>handleOpen('relock')}>Relock</WithDrawBtn>&nbsp;&nbsp;&nbsp;
        {getRmainingDays !== "Count Down end" ? (
          <WithDrawBtn className="disabled">WithDraw</WithDrawBtn>
        ) : (
          <WithDrawBtn onClick={()=>handleOpen()}>WithDraw</WithDrawBtn>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <ModalBox>
          <CloseButton onClick={()=>handleClose()}><CancelRoundedIcon /></CloseButton>

          {trx!== ''?
            <>
              <PromptImg src={logo2}></PromptImg>
              <PromptText id="child-modal-title">
                Lock TRANSACTION CREATED
              </PromptText>
              <LinkText href={SCANNER+"tx/"+trx} id="child-modal-description">
                View On Etherscan
              </LinkText>
              <ApproveBtn margin="15px 0px" onClick={()=>handleClose()}>
                Close
              </ApproveBtn>
            </>
            :
            <>
              {isWithdraw ?
                <>
                  <PromptText id="child-modal-title">Withdraw Tokens</PromptText>
                    <ColourText id="child-modal-title">{props.lockLP}</ColourText>
                    <SubText id="child-modal-title">Your Coins Are Unlocked.</SubText>
                    <DetailsDiv>
                      <InnerDiv>
                        <FirstText>Withdraw</FirstText>
                        <SecondText>{props.lockLP}</SecondText>
                      </InnerDiv>
                      <InnerDiv>
                        <FirstText>Deposit To</FirstText>
                        <SecondText>{(props.lpAddress).slice(0,4)}......{(props.lpAddress).substr((props.lpAddress).length - 5)}</SecondText>
                      </InnerDiv>
                      <div style={{height: 1, background: '#727272', marginTop: 30}} />
                      <InnerDiv>
                        <FirstText>Unlock Date</FirstText>
                        <ConfigureDiv>
                          {inputDateError? <ErorrMsg>Invalid date</ErorrMsg>: null}
                          <CustomInputDate 
                            value={inputDate}
                            contentEditable={true}
                            type="date"
                            onChange={(event) =>
                              handleDate(event)
                            }
                          />
                        </ConfigureDiv>
                      </InnerDiv>
                      
                      <ApproveBtn disabled={inputDateErrorBTN? true: false} margin="15px 0px" onClick={()=>reLock.write()}>Relock</ApproveBtn>
                    </DetailsDiv>
                    <ApproveBtn margin="15px 0px" onClick={()=>withdrawFunc.write()}>Withdraw</ApproveBtn>
                </>
                :
                <>
                  <PromptText id="child-modal-title">Relock Tokens</PromptText>
                    <ColourText id="child-modal-title">{props.lockLP}</ColourText>
                    <DetailsDiv>
                      <InnerDiv>
                        <FirstText>Relock</FirstText>
                        <SecondText>{props.lockLP}</SecondText>
                      </InnerDiv>
                      <div style={{height: 1, background: '#727272', marginTop: 30}} />
                      <InnerDiv>
                        <FirstText>Unlock Date</FirstText>
                        <ConfigureDiv>
                          {inputDateError? <ErorrMsg>Invalid date</ErorrMsg>: null}
                          <CustomInputDate 
                            value={inputDate}
                            contentEditable={true}
                            type="date"
                            onChange={(event) =>
                              handleDate(event)
                            }
                          />
                        </ConfigureDiv>
                      </InnerDiv>
                      <ApproveBtn disabled={inputDateErrorBTN? true: false} margin="15px 0px" onClick={()=>reLock.write()}>Relock</ApproveBtn>
                    </DetailsDiv>
                </>
              }
            </>
          }
                  
            
            
          </ModalBox>
        </Modal>
      </InnerGrid>
    </CustomGrid>
  );
};

export { WithDrawBtn };
export default MyLocks_Cards;
