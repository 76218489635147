import { useState } from "react";

const useDateFormate = (lpArg='') => {
    const [ getRmainingDays, setGetRmainingDays ] = useState('')
    const [ getDateFormate, setGetDateFormate ] = useState('')
    const [ getUnlockDateFormate, setGetUnlockDateFormate ] = useState('')

    const setRemainingDays = (expectedDate) => {
        let currentDate = new Date().getTime()/1000;
        let dateDifference = Math.abs(expectedDate - currentDate);
    
        // days Calculate
        if (expectedDate - currentDate > 0) {
            let days = Math.floor(dateDifference / 86400);
            dateDifference -= days * 86400;
            // calculate (and subtract) whole hours
            let hours = Math.floor(dateDifference / 3600) % 24;
            dateDifference -= hours * 3600;
            // calculate (and subtract) whole minutes
            let minutes = Math.floor(dateDifference / 60) % 60;
            dateDifference -= minutes * 60;
            // what's left is seconds
            let seconds = Math.floor(dateDifference % 60); 
             
            let formate = '';
            if ( days!= 0 ) formate += days+" Days : ";
            if ( hours!= 0 ) formate += hours+" Hours : ";
            if ( minutes!= 0 ) formate += minutes+" Min : ";
            if ( seconds!= 0 ) formate += seconds+" Sec";
            setGetRmainingDays( formate )
        }else{
            setGetRmainingDays("Count Down end")
        }
    }

    const setDateFormate = (timestamp,) => {
        let date = new Date(timestamp * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;

        setGetDateFormate(month + "/" + day + "/" + year + " " + hours + ':' + minutes + ' ' + ampm);
    }

    const setUnlockDateFormate = (timestamp,) => {
        let date = new Date(timestamp * 1000);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;

        setGetUnlockDateFormate(month + "/" + day + "/" + year + " " + hours + ':' + minutes + ' ' + ampm);
    }
 
    return { getRmainingDays, setRemainingDays, getDateFormate, setDateFormate, getUnlockDateFormate, setUnlockDateFormate }
}

export {useDateFormate}