import styled from "@emotion/styled";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const ConnectBTN = styled.div`
    background-color: ${ props=> props.theme.btnPrimary};
    display: flex;
    width: max-content;
    border-radius: 10px;
    font-family: "Poppins-Regular";
    button{
        color: ${(props) => props.theme.textPrimary};
        background-color: unset;
        border-radius: 10px;
        border: unset;
        outline: unset;
        font-size: 16px;
        font-weight: 700;
        padding: 10px;
        margin: auto;
        width: 154px;
        /* height: 48px; */

        &.add{border-radius: 10px 0 0 10px; padding: 8px 10px 8px 20px; width: unset;}
        &.dd{border-radius: 0 10px 10px 0; padding: 0px 15px 0px 10px; width: unset;}
        
        &:hover, &:active, &:focus {
            background: #00000021;
        }
        svg {
            fill: ${(props) => props.theme.textPrimary}; 
        }
    }
    &:hover, &:active, &:focus {
        background-color: ${(props) => props.theme.btnPrimaryHover};
    }
    
`

const DisConnectBTN = styled.div`
    width: 100%;
    border-radius: 9px;
    display: flex;
    color: ${props=> props.theme.textPrimary};
    justify-content: center;
`

const ContentCopyIconC = styled(ContentCopyIcon)`
  font-size: 15px;
  fill: ${props => props.theme.textPrimary};
  margin: -3px 5px 0 0;
`
const CustomMenu = styled(Menu)`
  ul {
    background-color: ${props => props.theme.bgPrimary}
  }
`
const CustomMenuItem = styled(MenuItem)`
  color: ${props => props.theme.textPrimary};
`

export {
    ConnectBTN,
    DisConnectBTN,
    ContentCopyIconC,
    CustomMenu,
    CustomMenuItem,
}